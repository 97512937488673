import { DndContext, DragOverlay, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useState } from 'react';
import Item from './Item';
import SortableItem from './SortableItem';
import { ScrollArea } from 'components/ui/scroll-area';

export default function SortablePhotos({ photos, setImageExpanded, onImageOrderChanged }) {
  const [items, setItems] = useState(photos.map((photo) => photo.name));
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5
      }
    })
  );

  useEffect(() => {
    setItems(photos.map((photo) => photo.name));
  }, [photos]);

  const handleDragStart = useCallback((event) => {
    setActiveId(event?.active?.id);
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        onImageOrderChanged(newItems);
        return newItems;
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}>
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <ScrollArea className="whitespace-nowrap max-h-64 overflow-y-auto">
          <div className="flex flex-wrap justify-start">
            {items.map((id) => (
              <SortableItem key={id} id={id} photo={photos.find((photo) => photo.name === id)} setImageExpanded={setImageExpanded} />
            ))}
          </div>
        </ScrollArea>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? (
          <Item id={activeId} photo={photos.find((photo) => photo.name === activeId)} isDragging setImageExpanded={setImageExpanded} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
