const dayjs = require('dayjs');
const uuid = require('uuid');

export const MINS_PER_DAY = 1440; // number of mins in a day

export const defaultCalendarStartDate = new Date(2015, 3, 12); // Fixed calendar date to keep display consistent over time. Must be a Sunday.

export const displayIntervalMinsForTitle = (minutes) => {
  if (minutes === undefined) {
    return '_ mins';
  }
  if (minutes === 60) {
    return 'hour';
  }
  if (minutes % 60 === 0) {
    const hours = minutes / 60;
    return hours + ' hours';
  }
  return minutes + ' mins';
};

export const formatFlatEventTitle = (rate) => `Parker pays $${rate} flat rate`;
export const formatIntervalEventTitle = (data) => {
  let title = `Parker pays $${data.rate} every ${displayIntervalMinsForTitle(data.interval)}`;
  if (data.dailyMax !== undefined) {
    title += `. $${data.dailyMax} daily max`;
  }
  return title;
};
export const formatVariableEventTitle = (rates) => {
  let printString = 'Variable Rates: ';
  for (const slot of rates) {
    printString += `${slot.start}-${slot.end} mins $${slot.rate} | `;
  }
  return printString;
};

// To help remember how the calendar relates to the database structure:
// Events are rate types, the whole weekly schedule is a rate structure. Slots within events are price structures.

//
// Converts calendar events into the equivilent rate structure and it's constituent rate types and price structures
//
export const convertCalendarEventsToRateStructure = (events, dayStartOffset) => {
  // Calendar days always start at 12am under the hood. Events are displayed to the user with fake offsetted times if they change the day start time.
  // We need to convert the events to the offsetted times that the user sees so that their intended times are set into the db.
  const offsetConvertedEvents = events.map((e) => ({
    ...e,
    start: dayjs(e.start).add(dayStartOffset, 'minutes').toDate(),
    end: dayjs(e.end).add(dayStartOffset, 'minutes').toDate()
  }));

  const rateTypes = [];
  const priceStructures = [];
  for (const event of offsetConvertedEvents) {
    // Default calendar behaviour is to end events on the hour or half hour. We actually want to end 1 second earlier given the db format.
    const modifiedEvent = dayjs(event.end).second() === 0 ? { ...event, end: dayjs(event.end).subtract(1, 'second').toDate() } : event;
    const eventDurationInSeconds = dayjs(event.end).diff(dayjs(event.start), 'second');
    const eventDurationInMinutes = Math.round(eventDurationInSeconds / 60);

    // convert event times into rate type object and add to list
    const eventRateType = {
      index: rateTypes.length,
      startDay: dayjs(modifiedEvent.start).day(),
      startTime: dayjs(modifiedEvent.start).format('HH:mm:ss'),
      endDay: dayjs(modifiedEvent.end).day(),
      endTime: dayjs(modifiedEvent.end).format('HH:mm:ss')
    };
    rateTypes.push(eventRateType);

    // convert event data into price structure object and add to list
    switch (modifiedEvent.type) {
      case 'interval': {
        // if the daily max is set we need to write this to the db as 2 different rate types
        if (modifiedEvent.data.rate && modifiedEvent.data.dailyMax) {
          const maxIntervals = Math.floor(modifiedEvent.data.dailyMax / modifiedEvent.data.rate);
          const remainder = modifiedEvent.data.dailyMax % modifiedEvent.data.rate;

          let lastChunk;
          let numRemainingIntervals;
          if (remainder) {
            lastChunk = remainder;
            numRemainingIntervals = maxIntervals;
          } else {
            lastChunk = modifiedEvent.data.rate;
            numRemainingIntervals = maxIntervals - 1;
          }

          const remainingIntervalsDuration = Math.min(numRemainingIntervals * modifiedEvent.data.interval, MINS_PER_DAY);
          if (remainingIntervalsDuration) {
            const eventPriceStructure = {
              rateTypeIndex: eventRateType.index,
              minMins: 0,
              maxMins: remainingIntervalsDuration,
              interval: modifiedEvent.data.interval,
              price: Math.round(modifiedEvent.data.rate * 100)
            };
            priceStructures.push(eventPriceStructure);
          }

          const maxPriceStructureDurationMins = Math.min(eventDurationInMinutes, MINS_PER_DAY);
          if (remainingIntervalsDuration < maxPriceStructureDurationMins) {
            const eventPriceStructure = {
              rateTypeIndex: eventRateType.index,
              minMins: remainingIntervalsDuration,
              maxMins: MINS_PER_DAY,
              interval: MINS_PER_DAY,
              price: Math.round(lastChunk * 100)
            };
            priceStructures.push(eventPriceStructure);
          }
        } else {
          const eventPriceStructure = {
            rateTypeIndex: eventRateType.index,
            minMins: 0,
            maxMins: MINS_PER_DAY,
            interval: modifiedEvent.data.interval,
            price: Math.round(modifiedEvent.data.rate * 100)
          };
          priceStructures.push(eventPriceStructure);
        }
        break;
      }
      case 'flat': {
        const eventPriceStructure = {
          rateTypeIndex: eventRateType.index,
          minMins: 0,
          maxMins: MINS_PER_DAY,
          interval: MINS_PER_DAY,
          price: Math.round(modifiedEvent.data.rate * 100)
        };
        priceStructures.push(eventPriceStructure);
        break;
      }
      case 'variable': {
        for (const slot of modifiedEvent.data.rates) {
          const slotPriceStructure = {
            rateTypeIndex: eventRateType.index,
            minMins: slot.start,
            maxMins: slot.end,
            interval: slot.end - slot.start,
            price: Math.round(slot.rate * 100)
          };
          priceStructures.push(slotPriceStructure);
        }
        break;
      }
      default:
        throw new Error(`Unexpected event type ${modifiedEvent.type}`);
    }
  }

  return { rateTypes, priceStructures };
};

const convertDayAndTimeToDayjsDate = (dow, time) => {
  const daysDifference = (dow - dayjs(defaultCalendarStartDate).day() + 7) % 7;
  let dayjsDate = dayjs(defaultCalendarStartDate).add(daysDifference, 'day');
  const TimeSplit = time.split(':');
  dayjsDate = dayjsDate.hour(parseInt(TimeSplit[0]));
  dayjsDate = dayjsDate.minute(parseInt(TimeSplit[1]));
  dayjsDate = dayjsDate.second(parseInt(TimeSplit[2]));
  return dayjsDate;
};

function isExactStartOfDay(date) {
  return date.hour() === 0 && date.minute() === 0 && date.second() === 0;
}

const checkIsIntervalWithDailyMax = (priceStructures, rateTypeDurationMins) => {
  if (priceStructures.length === 2) {
    const startPriceStructure = priceStructures.find((el) => el.minMins === 0);
    const endPriceStructure = priceStructures.find((el) => el.minMins === startPriceStructure.maxMins);

    if (
      startPriceStructure.maxMins < rateTypeDurationMins &&
      startPriceStructure.interval < startPriceStructure.maxMins &&
      !((startPriceStructure.maxMins - startPriceStructure.minMins) % startPriceStructure.interval) &&
      endPriceStructure.interval >= endPriceStructure.maxMins - endPriceStructure.minMins
    ) {
      return true;
    }
  }
  return false;
};

export const convertRateStructureToCalendarEvents = (rateStucture, dayStartOffset, currentWeekStart) => {
  const calendarWeekStartTime = dayjs(defaultCalendarStartDate).add(currentWeekStart, 'day').startOf('day');
  const events = [];
  for (const rateType of rateStucture.rateTypes) {
    let startTime = convertDayAndTimeToDayjsDate(rateType.startDay, rateType.startTime);
    let endTime = convertDayAndTimeToDayjsDate(rateType.endDay, rateType.endTime);
    // make sure we subtract the day start offset for correct calendar display
    startTime = startTime.subtract(dayStartOffset, 'minutes');
    endTime = endTime.subtract(dayStartOffset, 'minutes');

    // Lets make sure the events are on the week displayed on the calendar (not the week before or the week after)
    if (startTime.isBefore(calendarWeekStartTime)) {
      startTime = startTime.add(7, 'day');
    }
    if (startTime.isAfter(calendarWeekStartTime.add(7, 'day'))) {
      startTime = startTime.subtract(7, 'day');
    }
    if (endTime.isBefore(calendarWeekStartTime)) {
      endTime = endTime.add(7, 'day');
    }
    if (endTime.isAfter(calendarWeekStartTime.add(7, 'day'))) {
      endTime = endTime.subtract(7, 'day');
    }
    if (endTime.isBefore(startTime)) {
      // According to my calculations this should never happen unless the rate structure has reached an inconsistent state where
      // the week start time does not match an event start time (ie we have an event trying to jump across the calender from the end to the start)
      // We're better off throwing an error than trying to continue and displaying an inconsistent schedule to the user
      console.warn('Rate end time is earlier than start time');
      throw new Error('Rate end time is earlier than start time');
    }

    // events in db end 1 second before next starts. In the calander, the convention is for them to end and start same time.
    startTime = startTime.second() === 59 ? startTime.add(1, 'second') : startTime;
    endTime = endTime.second() === 59 ? endTime.add(1, 'second') : endTime;

    const rateTypeDurationMins = endTime.diff(startTime, 'minutes');

    // Validate and destructure the rateType
    let type;
    let title;
    let data;
    const associatedPriceStructures = rateStucture.priceStructures.filter((el) => el.rateTypeIndex === rateType.index);
    if (associatedPriceStructures.length > 1) {
      // Could be a Variable rate type or an interval rate type with a daily max
      if (checkIsIntervalWithDailyMax(associatedPriceStructures, rateTypeDurationMins)) {
        // Interval rate type with a daily max
        const intervalPriceStructure = associatedPriceStructures.find((el) => el.minMins === 0);
        const capPriceStructure = associatedPriceStructures.find((el) => el.minMins === intervalPriceStructure.maxMins);
        const maxNumIntervals = (intervalPriceStructure.maxMins - intervalPriceStructure.minMins) / intervalPriceStructure.interval;
        const maxDailyRateCents = intervalPriceStructure.price * maxNumIntervals + capPriceStructure.price;
        if (capPriceStructure.maxMins < Math.min(rateTypeDurationMins, MINS_PER_DAY)) {
          console.warn('Price Structure does not cover rate type duration');
          throw new Error('Price Structure does not cover rate type duration');
        }

        type = 'interval';
        data = { rate: intervalPriceStructure.price / 100, interval: intervalPriceStructure.interval, dailyMax: maxDailyRateCents / 100 };
        title = formatIntervalEventTitle(data);
      } else {
        // Variable rate type
        const startPriceStructure = associatedPriceStructures.find((el) => el.minMins === 0);
        let currentPriceStructure = startPriceStructure;
        const slots = [];
        for (let i = 0; i < associatedPriceStructures.length - 1; i++) {
          const slotDurationMins = currentPriceStructure.maxMins - currentPriceStructure.minMins;
          if (currentPriceStructure.interval < slotDurationMins) {
            // Variable slots can only support flat rates. We need to break the price structure into multiple
            let start = currentPriceStructure.minMins;
            let end = start + currentPriceStructure.interval;
            while (start < currentPriceStructure.maxMins) {
              slots.push({
                start,
                end: Math.min(end, currentPriceStructure.maxMins),
                rate: currentPriceStructure.price / 100
              });
              start = end;
              end += currentPriceStructure.interval;
            }
          } else {
            slots.push({
              start: currentPriceStructure.minMins,
              end: currentPriceStructure.maxMins,
              rate: currentPriceStructure.price / 100
            });
          }

          const currentMaxMins = currentPriceStructure.maxMins;
          const nextPriceStructure = associatedPriceStructures.find((el) => el.minMins === currentMaxMins);
          currentPriceStructure = nextPriceStructure;
        }
        slots.push({
          start: currentPriceStructure.minMins,
          end: currentPriceStructure.maxMins,
          rate: currentPriceStructure.price / 100
        });
        if (currentPriceStructure.maxMins < rateTypeDurationMins && currentPriceStructure.maxMins !== MINS_PER_DAY) {
          console.warn(`Variable rate price Structures do not cover rate type ${rateType.index} duration`);
          throw new Error(`Variable rate price Structures do not cover rate type ${rateType.index} duration`);
        }
        type = 'variable';
        data = { rates: slots };
        title = formatVariableEventTitle(slots);
      }
    } else if (associatedPriceStructures.length === 1) {
      // Interval or Flat rate type
      const priceStructure = associatedPriceStructures[0];
      if (priceStructure.maxMins >= rateTypeDurationMins || priceStructure.maxMins === MINS_PER_DAY) {
        if (priceStructure.interval >= rateTypeDurationMins || priceStructure.interval === MINS_PER_DAY) {
          // Valid flat rate
          type = 'flat';
          data = { rate: priceStructure.price / 100 };
          title = formatFlatEventTitle(data.rate);
        } else {
          // Valid interval rate
          type = 'interval';
          data = { rate: priceStructure.price / 100, interval: priceStructure.interval };
          title = formatIntervalEventTitle(data);
        }
      } else {
        console.warn('Price Structure does not cover rate type duration');
        throw new Error('Price Structure does not cover rate type duration');
      }
    } else {
      console.warn('No price structures associated with rateType');
      throw new Error('No price structures associated with rateType');
    }

    const start = startTime.toDate();
    // The calendar library is a bit buggy and doesn't like it when an event ends on the exact start of a day.
    // Instead these events should end 23:59:59 on the prior day
    const end = isExactStartOfDay(endTime) ? endTime.subtract(1, 'second').toDate() : endTime.toDate();

    events.push({
      start,
      end,
      id: uuid.v4(),
      type,
      title,
      duration: dayjs(end).diff(dayjs(start), 'minute'),
      data,
      index: events.length
    });
  }

  // Make sure we convert the events to the offsetted times so that the user sees the correct times displayed.
  return events;
};

export const convertDatabaseEFRToDayjsEFR = (efr) => {
  const entry = convertDayAndTimeToDayjsDate(efr.startDay, efr.startTime);
  const entryCutoff = convertDayAndTimeToDayjsDate(efr.startCutoffDay, efr.startCutoffTime);
  const exit = convertDayAndTimeToDayjsDate(efr.endDay, efr.endTime);

  return {
    entry,
    entryCutoff,
    exit,
    price: efr.price / 100,
    id: efr.id
  };
};

export const convertDayjsEFRToDatabaseEFR = (dayjsEfr) => ({
  startDay: dayjsEfr.entry.day(),
  startTime: dayjsEfr.entry.format('HH:mm:ss'),
  startCutoffDay: dayjsEfr.entryCutoff.day(),
  startCutoffTime: dayjsEfr.entryCutoff.format('HH:mm:ss'),
  endDay: dayjsEfr.exit.day(),
  endTime: dayjsEfr.exit.format('HH:mm:ss'),
  id: dayjsEfr.id,
  price: Math.round(dayjsEfr.price * 100)
});

export const convertOffsetToDayStartTime = (offset) => {
  const midnight = dayjs(defaultCalendarStartDate).startOf('day');
  const adjustedTime = midnight.add(offset, 'minutes');

  return {
    hrs: adjustedTime.format('hh'),
    mins: adjustedTime.format('mm'),
    ampm: adjustedTime.format('A')
  };
};

export const convertOffsetToTimeString = (offset) => {
  const midnight = dayjs(defaultCalendarStartDate).startOf('day');
  const adjustedTime = midnight.add(offset, 'minutes');
  return adjustedTime.format('HH:mm:ss');
};

export const convertSequilizeDayStartTimeToOffset = (startTimeString) => {
  const [hours, mins, secs] = startTimeString.split(':');
  let dayjsDate = dayjs(defaultCalendarStartDate).startOf('day');
  dayjsDate = dayjsDate.hour(parseInt(hours));
  dayjsDate = dayjsDate.minute(parseInt(mins));
  dayjsDate = dayjsDate.second(parseInt(secs));

  // If the time is after 12 PM, set the date to the day before
  if (parseInt(hours) > 12) {
    dayjsDate = dayjsDate.subtract(1, 'day');
  }

  const offset = dayjsDate.diff(dayjs(defaultCalendarStartDate).startOf('day'), 'minutes', true);
  return offset;
};

export const convertDayStartTimeToOffset = ({ hrs, mins, ampm }) => {
  let referenceDate = dayjs(defaultCalendarStartDate).startOf('day');

  // Set the hour, minutes, and AM/PM on the reference date
  referenceDate = referenceDate.set('hour', (hrs % 12) + (ampm === 'PM' ? 12 : 0));
  referenceDate = referenceDate.set('minute', mins);

  // If the time is after 12 PM, set the date to the day before
  if (ampm === 'PM' && hrs < 12) {
    referenceDate = referenceDate.subtract(1, 'day');
  }

  // Calculate the difference in minutes from midnight
  const offset = referenceDate.diff(dayjs(defaultCalendarStartDate).startOf('day'), 'minutes', true);

  return offset;
};

const dowMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};
export const convertInputTimeToDayjsTime = ({ hrs, mins, ampm, dow }) => {
  let referenceDate = dayjs(defaultCalendarStartDate).startOf('day');
  referenceDate = referenceDate.set('hour', (hrs % 12) + (ampm === 'PM' ? 12 : 0));
  referenceDate = referenceDate.set('minute', mins);
  if (dow) {
    referenceDate = referenceDate.set('day', dowMap[dow]);
  }
  return referenceDate;
};

export const displayMinsAsHoursAndMins = (minutes) => {
  if (Number.isNaN(minutes) || minutes < 0) {
    return 'NaN';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0 && remainingMinutes === 0) return '0 hr';

  if (hours === 0) {
    return `${remainingMinutes}min`;
  }
  if (remainingMinutes === 0) {
    return `${hours}hr`;
  }
  return `${hours}hr ${remainingMinutes}min`;
};

export const displayMinsAsHoursAndMinsShort = (minutes) => {
  if (Number.isNaN(minutes) || minutes < 0) {
    return 'NaN';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0 && remainingMinutes === 0) return '0h';

  if (hours === 0) {
    return `${remainingMinutes}m`;
  }
  if (remainingMinutes === 0) {
    return `${hours}h`;
  }
  return `${hours}h ${remainingMinutes}m`;
};
