import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { DashboardIcon, ExitIcon } from '@radix-ui/react-icons';
import {
  WrenchIcon,
  BanknotesIcon,
  ReceiptRefundIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  CircleStackIcon,
  ChartBarIcon,
  CalendarDaysIcon,
  MapPinIcon
} from '@heroicons/react/24/outline';
import { Avatar, AvatarFallback } from '../ui/avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { Badge } from '../ui/badge';
import NavBar from './NavBar';
import { AppContext } from '../../context/provider';

const isSmallScreen = window.innerWidth >= 1200 ? false : true;
function MainApp({ children, userEmail, logoutFunction, authEnabled }) {
  const [open, setOpen] = useState(isSmallScreen ? false : true);
  const [drawerWidth, setDrawerWidth] = useState(isSmallScreen ? '0' : '260px');
  const { permissions } = useContext(AppContext);
  const {
    hasEnforcementPermission,
    hasRateSettingsPermission,
    hasPromoCodePermission,
    hasRefundPortalPermission,
    hasUserPermission,
    hasUserRolePermission,
    hasClientAnalyticsPermission,
    hasDashboardPermission,
    hasLocationsPermissions
  } = permissions || {};
  const featureCSS = () =>
    'flex flex-row w-60 leading-6 rounded-md mb-1 py-2.5 px-4 font-medium text-sm text-[#ffffffb3] hover:text-white hover:bg-[#ffffff0d]';
  const activeFeatureCSS = () => ({ backgroundColor: '#ffffff1a', color: '#ffffff' });
  const badgeCSS = () => 'ml-auto my-auto h-5 rounded-xl px-1.5 text-black bg-white font-medium text-xs z-1';
  const iconCSS = () => 'mr-4 w-6 h-6';

  const handleDrawer = () => {
    setOpen((prev) => {
      if (prev) {
        setDrawerWidth(0);
      } else {
        setDrawerWidth('260px');
      }
      return !prev;
    });
  };

  const handleDrawerFromScreen = () => {
    if (isSmallScreen && open) {
      setOpen(false);
      setDrawerWidth(0);
    }
  };

  if (!userEmail) {
    return (
      <div>
        <div className="shadow-lg">
          <NavBar handleDrawer={null} homePageHandler={null} />
        </div>
        <div>{children}</div>
      </div>
    );
  }

  return (
    <div>
      <div
        className="fixed z-10 text-white/70 float-left bg-gray-900 h-screen flex"
        style={{
          width: drawerWidth,
          transition: 'width 0.25s'
        }}>
        <DrawerContent />
      </div>
      <div
        onClick={handleDrawerFromScreen}
        className="h-screen float-right overflow-y-scroll"
        style={{
          width: `${drawerWidth && !isSmallScreen ? `calc(100% - ${drawerWidth})` : '100%'}`,
          transition: 'width 0.25s'
        }}>
        <div className="fixed mb-4 shadow-lg w-full bg-white z-50">
          <NavBar
            open={open}
            handleDrawer={handleDrawer}
            width={`${drawerWidth && !isSmallScreen ? `calc(100% - ${drawerWidth})` : '100%'}`}
            hasDashboardPermission={hasDashboardPermission || false}
          />
        </div>
        <div className="py-4 px-4 mt-[60px]">{children}</div>
      </div>
    </div>
  );

  function DrawerContent() {
    return (
      <div className="mb-4 text-xs font-bold overflow-y-auto w-full">
        <div className="flex flex-col items-center mb-4 border-neutral-800 border-b pb-4">
          <div className="badge flex items-center justify-center font-medium text-[#5FDAFC] text-xl mb-4 leading-[44px] border-neutral-800 border-b w-full h-[60px]">
            Lightning Pay Admin
          </div>
          <Avatar className="w-20 h-20 mb-2 grow">
            <AvatarFallback className="bg-orange-600 text-3xl">
              {`${userEmail.charAt(0)?.toUpperCase()}${userEmail.split('.')[1].charAt(0).toUpperCase()}` || 'U'}
            </AvatarFallback>
          </Avatar>
          <p className="text-white w-60 overflow-hidden whitespace-nowrap text-ellipsis text-lg capitalize px-2">
            {userEmail.split('@')?.shift().replace('.', ' ') || 'User'}
          </p>
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger>
                <p className="text-xs w-60 overflow-hidden whitespace-nowrap text-ellipsis whitespace-nowrap text-sm font-medium text-[#94a3b8] px-2">
                  {userEmail || 'email@projectinflection.com'}
                </p>
              </TooltipTrigger>
              <TooltipContent className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-violet11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[14px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] font-light text-black">
                {userEmail || 'email@projectinflection.com'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="ml-[10px]">
          <NavLink exact to="/" activeStyle={activeFeatureCSS()} className={featureCSS()}>
            <DashboardIcon className={iconCSS()} />
            <p className="">Dashboard</p>
          </NavLink>
          {authEnabled && (
            <>
              {!hasDashboardPermission && hasClientAnalyticsPermission ? null : hasClientAnalyticsPermission ? (
                <NavLink to="/client-analytics" activeStyle={activeFeatureCSS()} className={featureCSS()}>
                  <ChartBarIcon className={iconCSS()} />
                  <p>Client Dashboard</p>
                  <Badge variant="secondary" className={badgeCSS()}>
                    NEW
                  </Badge>
                </NavLink>
              ) : null}
            </>
          )}
          {hasEnforcementPermission && (
            <>
              <NavLink to="/list" activeStyle={activeFeatureCSS()} className={featureCSS()}>
                <CircleStackIcon className={iconCSS()} />
                <p>Enforcement</p>
              </NavLink>
            </>
          )}
          {authEnabled && hasRateSettingsPermission && (
            <NavLink to="/rate-selection" activeStyle={activeFeatureCSS()} className={featureCSS()}>
              <WrenchIcon className={iconCSS()} />
              <p>Configure Rates</p>
              <Badge variant="secondary" className={badgeCSS()}>
                NEW
              </Badge>
            </NavLink>
          )}
          {authEnabled && hasRateSettingsPermission && (
            <>
              <NavLink to="/rates" activeStyle={activeFeatureCSS()} className={featureCSS()}>
                <CalendarDaysIcon className={iconCSS()} />
                <p>Rate Changes</p>
              </NavLink>
            </>
          )}
          {authEnabled && hasLocationsPermissions && (
            <>
              <NavLink to="/locations" activeStyle={activeFeatureCSS()} className={featureCSS()}>
                <MapPinIcon className={iconCSS()} />
                <p>Locations</p>
              </NavLink>
            </>
          )}
          {authEnabled && hasPromoCodePermission && (
            <NavLink to="/promo-codes" activeStyle={activeFeatureCSS()} className={featureCSS()}>
              <BanknotesIcon className={iconCSS()} />
              <p>Promo Codes</p>
            </NavLink>
          )}
          {authEnabled && hasRefundPortalPermission && (
            <NavLink to="/refund-portal" activeStyle={activeFeatureCSS()} className={featureCSS()}>
              <ReceiptRefundIcon className={iconCSS()} />
              <p>Refund Portal</p>
            </NavLink>
          )}
          {authEnabled && hasUserPermission && (
            <NavLink to="/user-config" activeStyle={activeFeatureCSS()} className={featureCSS()}>
              <UsersIcon className={iconCSS()} />
              <p>Users</p>
            </NavLink>
          )}
          {authEnabled && hasUserRolePermission && (
            <NavLink to="/role-config" activeStyle={activeFeatureCSS()} className={featureCSS()}>
              <WrenchScrewdriverIcon className={iconCSS()} />
              <p>User Roles</p>
            </NavLink>
          )}
          <NavLink
            to="/"
            className={featureCSS()}
            onClick={() => {
              logoutFunction();
            }}>
            <ExitIcon className={iconCSS()} />
            <p>Logout</p>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default MainApp;
