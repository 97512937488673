import { Label } from 'components/ui/label';
import { useForm } from 'react-hook-form';

import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { useState } from 'react';

const MAX_FLAT_RATE_DOLLARS = 1000; // Maximum flat rate the user can enter

export default function FlatRateTypeForm({ onSubmit, defaultValues = '' }) {
  const form = useForm();
  const [rate, setRate] = useState(defaultValues?.rate ?? undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = () => {
    const rateNumber = parseFloat(rate);
    if (rateNumber === undefined || Number.isNaN(rateNumber)) {
      setErrorMessage('Please input a rate');
      return;
    }
    setErrorMessage(null);
    onSubmit({ rate: rateNumber, type: 'flat' });
  };

  const handleRateInputChange = (value) => {
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      if (parseFloat(validated) > MAX_FLAT_RATE_DOLLARS) {
        setRate(MAX_FLAT_RATE_DOLLARS.toString());
        return;
      }
      if (parseFloat(validated) < 0) {
        setRate('0');
        return;
      }

      setRate(value);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-2 mt-4">
        <Label className="text-xl mr-1">$</Label>
        <Input className="w-16 mr-2" value={rate} onChange={(event) => handleRateInputChange(event.target.value)} />
      </div>

      {errorMessage && <p className="text-[0.8rem] font-medium text-destructive">{errorMessage}</p>}
      <p className="text-sm text-muted-foreground mb-2 mt-2">
        The parker will be charged <span className="font-bold">{rate !== undefined ? `$${rate}` : '$_'}</span> for parking within this time period.
      </p>
      {!defaultValues && (
        <div className="flex justify-end">
          <Button onClick={handleSubmit}>Set Rate</Button>
        </div>
      )}
      <form onSubmit={form.handleSubmit(handleSubmit)} id="flat-rate-type-form"></form>
    </div>
  );
}
