export const features = {
  PROMO_CODES: 'promoCodes',
  USER_ROLES: 'userRoles',
  USERS: 'users',
  RATE_SETTINGS: 'rateSettings',
  ENFORCEMENT: 'enforcement',
  REFUND_PORTAL: 'refundPortal',
  DASHBOARD: 'dashboard',
  CLIENT_ANALYTICS: 'clientAnalytics',
  LOCATION: 'location'
};

export const shortFeatureNames = {
  promoCodes: 'pc',
  userRoles: 'ur',
  users: 'u',
  rateSettings: 'rs',
  enforcement: 'e',
  refundPortal: 'rp',
  dashboard: 'da',
  clientAnalytics: 'ca',
  location: 'lo',

  PROMO_CODES: 'pc',
  USER_ROLES: 'ur',
  USERS: 'u',
  RATE_SETTINGS: 'rs',
  ENFORCEMENT: 'e',
  REFUND_PORTAL: 'rp',
  DASHBOARD: 'da',
  CLIENT_ANALYTICS: 'ca',
  LOCATION: 'lo'
};

export const access = {
  CAN_VIEW: 'canView',
  CAN_ADD: 'canAdd',
  CAN_EDIT: 'canEdit',
  CAN_DELETE: 'canDelete'
};

export const shortAccess = {
  [access.CAN_VIEW]: 'v',
  [access.CAN_ADD]: 'a',
  [access.CAN_EDIT]: 'e',
  [access.CAN_DELETE]: 'd'
};
