import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import MyProvider from './context/provider';
import { MockDataProvider } from './context/mockDataProvider';
import { Toaster } from 'components/ui/toaster';
import './globals.css';
import { TooltipProvider } from 'components/ui/tooltip';

const domain = window.location.href.split('/')[2];
if (domain === 'lightningpayadmin.propark.com') {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    service: 'lightning-pay-admin',
    sampleRate: 100
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <SnackbarProvider
    dense
    hideIconVariant
    maxSnack={1}
    autoHideDuration={5000}
    action={(snackbarId) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)} size="small">
        <Close />
      </IconButton>
    )}>
    <TooltipProvider>
      <Router>
        <MyProvider>
          <MockDataProvider>
            <App />
            <Toaster />
          </MockDataProvider>
        </MyProvider>
      </Router>
    </TooltipProvider>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
