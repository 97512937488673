import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { Skeleton } from 'components/ui/skeleton';
import { useToast } from 'components/ui/use-toast';
import { cn } from 'lib/utils';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAllAmenitiesAPI,
  getAllParkingInfoAPI,
  getGateSettingsAPI,
  getLocationDetailsAPI,
  getLocationTRDetailsAPI,
  getPhotosForLocationAPI,
  updateGateSettingsAPI,
  updateLocationTRDetailsAPI,
  updateLocationsDetailsAPI,
  updateTREnabledStatusAPI
} from '../../services/ManageLocations';
import GeneralTab from './GeneralTab';
import LightningPayTab from './LightningPayTab';
import TransientReservationsTab from './TransientReservationsTab';
import InfoTab from './InfoTab';
import { getFileURL } from '../../services/getFileURL';
import { AppContext } from '../../context/provider';
import GateSettingsTab from './GateSettingsTab';

const tabs = [
  {
    id: 'Info',
    title: 'Info'
  },
  {
    id: 'General',
    title: 'General'
  },
  {
    id: 'LP',
    title: 'Lightning Pay'
  },
  {
    id: 'TRS',
    title: 'Transient Reservations'
  },
  {
    id: 'GateSettings',
    title: 'Gate Settings',
    version: 4 // will only be displayed on locations with this version number
  }
];

const extractHeightRestrictionFromFacilities = (facilities) => {
  const heightRestrictionString = (facilities ?? {})['Height Restriction'] ?? undefined;
  if (heightRestrictionString === undefined) {
    return undefined;
  }

  const match = heightRestrictionString.match(/\d+(\.\d+)?/);
  return match[0] ?? undefined;
};

export default function EditLocation() {
  const { locationId } = useParams();
  const [tabSelected, setTabSelected] = useState('General');
  const [locationSettings, setLocationSettings] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isTrEnabledSaving, setIsTrEnabledSaving] = useState(false);
  const [qrUrls, setQrUrls] = useState([]);
  const { permissions } = useContext(AppContext);
  const { toast } = useToast();

  const canEditLocation = permissions.locationsPermissions[locationId]?.canEdit ?? false;
  const canViewLocation = permissions.locationsPermissions[locationId]?.canView ?? false;

  // Get lot name out of URL so it loads immediately
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const locationName = params.get('name');

  const getQRUrls = async ({ obscuredLocationID, signs }) => {
    try {
      const qrPromises = signs.map(async (sign) => {
        const qrURL = await getFileURL(`QRCodes/${obscuredLocationID}/${sign.obscuredId}.png`);
        return { obscuredSignId: sign.obscuredId, qrURL };
      });
      const allQRUrls = await Promise.all(qrPromises);
      setQrUrls(allQRUrls ?? []);
    } catch (error) {
      console.error('Error fetching QR URLs:', error);
    }
  };

  const getCurrentLocationSettings = async () => {
    try {
      const [locationDetailsResponse, photosResponse, amenitiesResponse, parkingInfoResponse, trDetailsResponse, gateSettingsResponse] =
        await Promise.all([
          getLocationDetailsAPI({ locationId }),
          getPhotosForLocationAPI({ locationId }),
          getAllAmenitiesAPI(),
          getAllParkingInfoAPI(),
          getLocationTRDetailsAPI({ locationId }),
          getGateSettingsAPI({ locationId })
        ]);
      if (!locationDetailsResponse.success || !locationDetailsResponse.data) {
        throw new Error('Unable to get details for location');
      }
      if (!photosResponse.success || !photosResponse.data) {
        throw new Error('Unable to get photos for location');
      }
      if (!amenitiesResponse.success || !amenitiesResponse.data) {
        throw new Error('Unable to get amenities');
      }
      if (!parkingInfoResponse.success || !parkingInfoResponse.data) {
        throw new Error('Unable to get parking info');
      }
      if (!trDetailsResponse.success || !trDetailsResponse.data) {
        throw new Error('Unable to get TR details for location');
      }
      if (!gateSettingsResponse.success || !gateSettingsResponse.data) {
        throw new Error('Unable to get Gate Settings for location');
      }
      const trDetails = trDetailsResponse.data;
      const locationDetails = locationDetailsResponse.data;
      const gateSettings = gateSettingsResponse.data;
      const orderedLocationPhotos = photosResponse.data.slice().sort((a, b) => a.orderIndex - b.orderIndex);
      const coords = locationDetails.coords.lat !== null && locationDetails.coords.lng !== null ? locationDetails.coords : undefined;
      setLocationSettings({
        info: {
          id: locationDetails.id ?? undefined,
          signs: locationDetails.signs ?? [],
          version: locationDetails.version
        },
        general: {
          locationName: locationDetails.name ?? undefined,
          address: locationDetails.address ?? undefined,
          fullAddress: locationDetails.fullAddress ?? undefined,
          coords: coords,
          city: locationDetails.city ?? undefined,
          state: locationDetails.state ?? undefined,
          zephireLevels: locationDetails.zephireLevels?.map((level) => ({ value: level.level, label: level.name })) ?? []
        },
        lightningPay: {
          gracePeriod: locationDetails.gracePeriod ?? 0,
          managerEmail: locationDetails.managerEmail ?? null,
          taxRate: locationDetails.stripeTaxRate ? { included: false, rate: locationDetails.stripeTaxRate.percentage } : { included: true },
          fees: locationDetails.serviceFees.map((fee) => ({
            description: fee.descriptor,
            type: fee.flatAmount !== null ? 'dollars' : 'percent',
            value: fee.flatAmount !== null ? fee.flatAmount / 100 : fee.percentage
          }))
        },
        transientReservations: {
          photos: orderedLocationPhotos,
          amenitiesOptions: amenitiesResponse.data.map((el) => ({ label: el.name, value: el.id })),
          parkingInfoOptions: parkingInfoResponse.data,
          gracePeriod: trDetails.gracePeriod ?? 0,
          phone: trDetails.phone?.toString() ?? undefined,
          heightRestriction: extractHeightRestrictionFromFacilities(trDetails.facilities),
          email: trDetails.email ?? '',
          gettingThere: trDetails.gettingThere ?? undefined,
          amenities: (trDetails.trAmenities ?? []).map((el) => ({ label: el.name, value: el.id })),
          parkingInfo: trDetails.parkingInfo?.id ?? undefined,
          aboutLocationPoints: trDetails.aboutLocation?.length ? trDetails.aboutLocation.map((el) => ({ value: el.description })) : [{ value: '' }],
          canBeEnabled: trDetails?.locationId ? true : false, // If a db entry exists in tr_locations, tr details have been entered and tr can be enabled for location
          blackOutDates: trDetails.trBlackoutDates || [],
          maxConcurrentStays: locationDetails.maxConcurrentStays,
          enableTrs: trDetails?.enabled ?? false
        },
        gateSettings: {
          maxRetries: gateSettings.maxRetries ?? undefined,
          retryInterval: gateSettings.retryInterval ?? undefined,
          firstWarningText: gateSettings.firstWarningText ?? undefined,
          finalFailureText: gateSettings.finalFailureText ?? undefined
        }
      });
      getQRUrls({ obscuredLocationID: locationDetails.obscuredID, signs: locationDetails.signs ?? [] });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getCurrentLocationSettings();
  }, [locationId]);

  const displayTab = (tabId) => {
    if (!locationSettings) {
      return (
        <div className="max-w-2xl space-y-9">
          <Skeleton className="h-20 w-full" />

          {[1, 2, 3, 4, 5].map((el, idx) => (
            <Skeleton className="h-8 w-full" key={idx} />
          ))}
        </div>
      );
    }

    switch (tabId) {
      case 'General':
        return <GeneralTab defaultValues={locationSettings.general} onSubmit={onSubmitGeneral} disabled={isSaving} canEdit={canEditLocation} />;
      case 'LP':
        return (
          <LightningPayTab
            defaultValues={locationSettings.lightningPay}
            onSubmit={onSubmitLightningPay}
            disabled={isSaving}
            canEdit={canEditLocation}
          />
        );
      case 'TRS':
        return (
          <TransientReservationsTab
            locationId={locationId}
            defaultValues={locationSettings.transientReservations}
            onSubmit={onSubmitTransientReservations}
            disabled={isSaving}
            isTrEnabledSaving={isTrEnabledSaving}
            canEdit={canEditLocation}
          />
        );
      case 'Info':
        return (
          <InfoTab values={locationSettings.info} qrUrls={qrUrls} refreshLocationSettings={getCurrentLocationSettings} locationId={locationId} />
        );
      case 'GateSettings':
        return (
          <GateSettingsTab
            defaultValues={locationSettings.gateSettings}
            onSubmit={onSubmitGateSettings}
            disabled={isSaving}
            canEdit={canEditLocation}
          />
        );
      default:
        return <></>;
    }
  };

  const onSubmitGeneral = async (values) => {
    toast({
      title: `Saving changes`,
      description: 'Please be patient. This may take a while'
    });
    setIsSaving(true);
    try {
      const { success, data } = await updateLocationsDetailsAPI({
        payload: {
          locationId,
          details: {
            name: values.locationName,
            city: values.city,
            state: values.state,
            timezone: values.timezone,
            address: values.address,
            fullAddress: values.fullAddress,
            coords: values.coords,
            zephireLevels: values.zephireLevels
          }
        }
      });
      if (!success || !data) {
        throw new Error('Unable to update location details');
      }
      toast({
        title: `Changes saved!`
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to update your location details. Please let us know!'
      });
    }
    setIsSaving(false);
    getCurrentLocationSettings();
  };

  const onSubmitLightningPay = async (values) => {
    toast({
      title: `Saving changes`,
      description: 'Please be patient. This may take a while'
    });
    setIsSaving(true);
    try {
      const { success, data } = await updateLocationsDetailsAPI({
        payload: {
          locationId,
          details: {
            gracePeriod: values.gracePeriod,
            managerEmail: values.managerEmail,
            newTaxRatePercent: values.newTaxRatePercent,
            serviceFees: values.serviceFees
          }
        }
      });
      if (!success || !data) {
        throw new Error('Unable to update location details');
      }
      toast({
        title: `Changes saved!`
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to update your location details. Please let us know!'
      });
    }
    setIsSaving(false);
    getCurrentLocationSettings();
  };

  const onSubmitGateSettings = async (values) => {
    toast({
      title: `Saving changes`,
      description: 'Please be patient. This may take a while'
    });
    setIsSaving(true);
    try {
      const { success, data } = await updateGateSettingsAPI({
        payload: {
          locationId,
          details: {
            maxRetries: values.maxRetries,
            retryInterval: values.retryInterval,
            firstWarningText: values.firstWarningText,
            finalFailureText: values.finalFailureText
          }
        }
      });
      if (!success || !data) {
        throw new Error('Unable to update gate settings');
      }
      toast({
        title: `Changes saved!`
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to update your location details. Please let us know!'
      });
    }
    setIsSaving(false);
    getCurrentLocationSettings();
  };

  const onSubmitTransientReservations = async (values) => {
    toast({
      title: `Saving changes`,
      description: 'Please be patient. This may take a while'
    });
    setIsSaving(true);
    try {
      const { success, data } = await updateLocationTRDetailsAPI({
        payload: {
          locationId,
          details: {
            gracePeriod: values.gracePeriod,
            gettingThere: values.gettingThere,
            phone: values.phone,
            email: values.email,
            parkingInfoId: values.parkingInfo,
            facilities: values.facilitiesObject,
            aboutLocation: values.aboutLocation,
            trAmenities: values.trAmenities,
            addTrBlackoutDates: values.addTrBlackoutDates,
            removeTrBlackoutDates: values.removeTrBlackoutDates,
            maxConcurrentStays: values.maxConcurrentStays
          }
        }
      });
      if (!success || !data) {
        throw new Error('Unable to update location details');
      }

      await setTREnabledStatus(values.enableTrs);

      // Update location settings so Transient reservations tabs knows the db entry has been inserted and the user can enable TR if they want
      setLocationSettings((prevSettings) => ({
        ...prevSettings,
        transientReservations: {
          ...prevSettings.transientReservations,
          canBeEnabled: true
        }
      }));
      toast({
        title: `Changes saved!`
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to update your location details. Please let us know!'
      });
    }
    setIsSaving(false);
    getCurrentLocationSettings();
  };

  const setTREnabledStatus = async (isEnabled) => {
    setIsTrEnabledSaving(true);
    try {
      const { success, data } = await updateTREnabledStatusAPI({
        payload: {
          locationId,
          isEnabled
        }
      });
      if (!success || !data) {
        throw new Error('Unable to update TR Enabled status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setIsTrEnabledSaving(false);
    getCurrentLocationSettings();
  };

  if (!canViewLocation) {
    return <></>;
  }

  return (
    <div>
      <Card className="sm:px-8">
        <CardHeader className="pb-0 mb-0 pt-6 text-left">
          <div className="space-y-0.5">
            <h2 className="text-2xl font-bold tracking-tight">{locationName}</h2>
            {/* <p className="text-muted-foreground">Edit the settings and preferences for this location.</p> */}
          </div>
        </CardHeader>
        <CardContent className="text-left">
          <Separator className="my-6" />
          <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
            <aside className="-mx-4 lg:w-1/5">
              <nav className="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
                {tabs
                  .filter((tab) => tab.version === undefined || locationSettings?.info?.version === tab.version)
                  .map((item) => (
                    <Button
                      variant="ghost"
                      key={item.id}
                      onClick={() => setTabSelected(item.id)}
                      className={cn(tabSelected === item.id ? 'bg-muted hover:bg-muted' : 'hover:bg-transparent hover:underline', 'justify-start')}>
                      {item.title}
                    </Button>
                  ))}
              </nav>
            </aside>
            <div className="flex-1 lg:max-w-4xl min-h-[70vh]">{displayTab(tabSelected)}</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
