import axios from 'axios';
import { auth } from './firebase';
import { API_BASE_URL } from './config';
import getJwtToken from '../util/getJwtToken';
import authErrorHandler from '../util/authErrorHandler';

axios.defaults.withCredentials = true;

// reusable function to make a request to our APIs
const api = async ({ url, payload, method = 'post', token = null }, cancelToken = null) => {
  try {
    const firebaseToken = token || (await auth?.currentUser?.getIdToken());
    if (!firebaseToken) {
      return { success: false, message: '' };
    }
    const retrieveResults = await axios({
      method,
      mode: 'no-cors',
      headers: {
        Authorization: `Bearer ${firebaseToken}`,
        ...getJwtToken()
      },
      url: API_BASE_URL + url,
      data: payload,
      ...(method === 'get' && { params: payload }),
      ...(cancelToken && { cancelToken })
    });
    if (retrieveResults?.data && retrieveResults?.data?.success === false) {
      return { success: false, message: retrieveResults?.data?.message };
    }
    return { success: true, data: retrieveResults?.data?.data || retrieveResults?.data?.message || retrieveResults?.data };
  } catch (error) {
    if (error.response && error.response.data) {
      await authErrorHandler(error);
      return { success: false, message: error.response.data?.error || error.response.data?.message || error.response.data };
    }
    console.log(error.message);
    return { success: false, message: error.message };
  }
};

export default api;
