import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { useStepper } from 'components/ui/stepper';
import { Info } from 'lucide-react';
import React, { useCallback, useRef, useState } from 'react';

const mapStyle = {
  height: '100%',
  width: '100%',
  cursor: 'default'
};
const NEW_YORK_CITY_COORDS = {
  lat: 40.7128,
  lng: -74.006
};
const DEFAULT_ZOOM = 5;
const ON_LOCATION_SEARCH_ZOOM = 18;

const getStreetAddress = (addressComponents) => {
  let streetNumber = '';
  let streetName = '';

  if (!addressComponents) {
    return undefined;
  }

  addressComponents.forEach((component) => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      streetName = component.long_name;
    }
  });

  if (!streetNumber && !streetName) {
    return undefined;
  }

  return `${streetNumber} ${streetName}`;
};

const getInfoFromPlace = (place, timezoneData, markerPosition) => {
  const properties = {};
  const streetAddress = getStreetAddress(place?.address_components);
  if (streetAddress) {
    properties.address = streetAddress;
  }
  if (place?.formatted_address) {
    properties.fullAddress = place?.formatted_address;
  }
  if (place?.address_components) {
    const state = place?.address_components.find((el) => el.types?.includes('administrative_area_level_1'));
    if (state?.short_name) {
      properties.state = state.short_name;
    }

    const city = place?.address_components.find((el) => el.types?.includes('locality'));
    if (city?.long_name) {
      properties.city = city.long_name;
    }
  }
  if (timezoneData?.timeZoneId) {
    properties.timezone = timezoneData.timeZoneId;
  }
  if (markerPosition) {
    const lat = markerPosition.lat();
    const lng = markerPosition.lng();
    if (lat && lng) {
      properties.coords = {
        lat,
        lng
      };
    }
  }

  return properties;
};

export default function SearchableMap({ onConfirmLocation }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY
  });
  const { nextStep } = useStepper();

  const [map, setMap] = useState(null);
  const [searchedPlace, setSearchedPlace] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [mapCenter, setMapCenter] = useState(NEW_YORK_CITY_COORDS);
  const [confirmedlocation, setConfirmedlocation] = useState(false);
  const [timezoneData, setTimezoneData] = useState(null);

  const inputRef = useRef();

  const fetchTimezoneData = async (latitude, longitude) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
      const currentUnixTimestampInSeconds = Math.floor(Date.now() / 1000);
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${currentUnixTimestampInSeconds}&key=${apiKey}`
      );
      if (!response?.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTimezoneData(data);
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  if (inputRef.current) {
    const autoComplete = new window.google.maps.places.Autocomplete(inputRef.current, { componentRestrictions: { country: ['US'] } });
    autoComplete.addListener('place_changed', () => {
      setConfirmedlocation(false);
      const place = autoComplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
      }
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetchTimezoneData(lat, lng);
        setSearchedPlace(place);
        map.setCenter(place.geometry.location);
        map.setZoom(ON_LOCATION_SEARCH_ZOOM);
        setMarkerPosition(place.geometry.location);
      } else {
        setMarkerPosition(null);
      }
    });
  }

  const onConfirmButtonClick = () => {
    // Extract the relevent information from the searched place
    const placeDetails = getInfoFromPlace(searchedPlace, timezoneData, markerPosition);
    onConfirmLocation(placeDetails);

    nextStep();
  };

  const onLoad = React.useCallback(
    (map) => {
      setMap(map);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleClickOnMap = (props) => {
    setConfirmedlocation(true);
    setMarkerPosition(props.latLng);
    const lat = props.latLng.lat();
    const lng = props.latLng.lng();
    fetchTimezoneData(lat, lng);
  };

  return (
    <div>
      <div className="text-left mb-1 px-2">
        <Label htmlFor="searchloc" className="align-left">
          Enter the location of your parking lot
        </Label>
      </div>
      <Input id="searchloc" placeholder="Enter an address" ref={inputRef} className="mb-4" />
      {isLoaded ? (
        <div className="h-[300px] sm:h-[500px]">
          <GoogleMap
            onLoad={onLoad}
            center={mapCenter}
            zoom={DEFAULT_ZOOM}
            mapContainerStyle={mapStyle}
            onClick={handleClickOnMap}
            onUnmount={onUnmount}
            clickableIcons={false}
            options={{
              draggableCursor: searchedPlace ? 'default' : null
            }}>
            <Marker position={markerPosition} opacity={confirmedlocation ? 1 : 0.4} />
          </GoogleMap>
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col sm:flex-row justify-between sm:items-center max-sm:justify-center mt-4 bg-muted p-3 rounded-lg h-auto sm:h-16">
        <div className="flex sm:items-center max-sm:mb-2">
          <Info className="h-4 w-4 mr-1 max-sm:mt-1" />
          <p className="text-sm font-medium ">
            {!searchedPlace
              ? '(1 of 3) Enter the address of your parking lot'
              : confirmedlocation
                ? '(3 of 3) Please confirm your location'
                : '(2 of 3) Click the map to drop a pin on the entrance of your location. This will help parkers find your lot.'}
          </p>
        </div>
        {confirmedlocation && <Button onClick={onConfirmButtonClick}>Confirm Location</Button>}
      </div>
    </div>
  );
}
