import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Step, Stepper } from 'components/ui/stepper';
import { useToast } from 'components/ui/use-toast';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createNewLocationAPI } from '../../services/AddLocation';
import LocationDetailsForm from './LocationDetailsForm';
import LotDetailsForm from './LotDetailsForm';
import SearchableMap from './SearchableMap';
import { Loader2, CheckCircle, Frown } from 'lucide-react';
import { Button } from 'components/ui/button';

const steps = [
  { key: 'selectLocation', label: 'Select Location' },
  { key: 'locationDetails', label: 'Location Details' },
  { key: 'lotDetails', label: 'Lot Details' }
];

export default function AddLocationCard() {
  const [searchedPlaceDetails, setSearchedPlaceDetails] = useState({});
  const [locationDetailsFormData, setLocationDetailsFormData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [lotDetailsFormData, setLotDetailsFormData] = useState({});
  const [creationStatus, setCreationStatus] = useState('idle'); // 'idle', 'loading', 'success'
  const [error, setError] = useState(null);
  const { toast } = useToast();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    // this useffect is mainly just to have a nice visual transaition between loading and success states
    if (creationStatus === 'success') {
      setTimeout(() => setShowSuccess(true), 300);
      setTimeout(() => {
        history.push(
          `/add-location/success?reload=true&name=${encodeURIComponent(locationDetailsFormData.locationName)}&id=${encodeURIComponent(locationDetailsFormData.locationId)}`
        );
      }, 2000);
    }
  }, [creationStatus, history, locationDetailsFormData]);

  const onConfirmLocation = (placeDetails) => {
    setSearchedPlaceDetails(placeDetails);
  };

  const onEnterLocationDetails = (formData) => {
    setLocationDetailsFormData(formData);
  };

  const createLocation = async (lotDetailsFormData) => {
    setCreationStatus('loading');
    try {
      const { success, data } = await createNewLocationAPI({
        payload: {
          locationName: locationDetailsFormData.locationName,
          locationId: locationDetailsFormData.locationId,
          fullAddress: searchedPlaceDetails.fullAddress,
          address: locationDetailsFormData.address,
          city: locationDetailsFormData.city,
          state: locationDetailsFormData.state,
          timezone: locationDetailsFormData.timezone,
          version: lotDetailsFormData.version,
          lanes: lotDetailsFormData.lanes,
          locationCoords: searchedPlaceDetails.coords,
          gracePeriod: lotDetailsFormData.gracePeriod,
          isCloudpark: lotDetailsFormData.isCloudpark,
          serviceFees: lotDetailsFormData.serviceFees,
          additionalTaxPercent: lotDetailsFormData.additionalTaxPercent
        }
      });
      if (!success || !data) {
        throw new Error('Unable to add new location');
      }

      setCreationStatus('success');
    } catch (error) {
      console.error('Error:', error);
      setError({ title: 'Something went wrong!', message: 'Please send an email to jake.conniff@propark.com so we can fix this' });
      setCreationStatus('idle');
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to add your new location. Please let us know!'
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onResetForm = () => {
    setSearchedPlaceDetails({});
    setLocationDetailsFormData({});
    setLotDetailsFormData({});
  };

  const onEnterLotDetails = async (formData) => {
    setLotDetailsFormData(formData);
    await createLocation(formData);
  };

  const displayStepContent = (stepProps) => {
    switch (stepProps.key) {
      case 'selectLocation':
        return (
          <div className="mr-1 sm:mx-10">
            <SearchableMap onConfirmLocation={onConfirmLocation} />
          </div>
        );

      case 'locationDetails':
        return (
          <div className="sm:mx-10 mr-1">
            <LocationDetailsForm
              defaultValues={{
                ...searchedPlaceDetails,
                locationName: locationDetailsFormData.locationName ?? '',
                locationId: locationDetailsFormData.locationId ?? undefined
              }}
              onSubmitCallback={onEnterLocationDetails}
            />
          </div>
        );
      case 'lotDetails':
        return (
          <div className="sm:mx-10 mr-1">
            <LotDetailsForm onSubmitCallback={onEnterLotDetails} savingNewLocation={creationStatus === 'loading'} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="relative">
      <Card>
        <div className="max-w-7xl mx-auto">
          <CardHeader className="pb-0 mb-4 pt-1 md:pt-3">
            <CardTitle className="text-lg md:text-2xl">Add a New Location</CardTitle>
          </CardHeader>
          <CardContent className="max-sm:pr-1 max-sm:pl-1">
            <Stepper initialStep={0} steps={steps} className="mb-8">
              {steps.map((stepProps) => (
                <Step {...stepProps}>
                  <div className="mb-8">{displayStepContent(stepProps)}</div>
                </Step>
              ))}

              {error && (
                <div className="text-center p-8 bg-red-50 p-8 rounded-lg shadow-inner">
                  <Frown className="h-20 w-20 mx-auto text-red-500 mb-6 animate-bounce" />
                  <p className="text-2xl font-semibold mb-4 text-red-700">{error.title}</p>
                  <p className="text-lg mb-6 text-red-600">{error.message}</p>
                  <div className="flex justify-center space-x-4">
                    <Button
                      onClick={() => {
                        window.location.reload();
                      }}
                      variant="outline"
                      className="border-red-500 text-red-500 hover:bg-red-50">
                      Try Again
                    </Button>
                    <Button onClick={() => history.push('/')} variant="ghost" className="text-red-500 hover:bg-red-50">
                      Go to Dashboard
                    </Button>
                  </div>
                </div>
              )}
            </Stepper>
          </CardContent>
        </div>
      </Card>

      {creationStatus !== 'idle' && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white p-10 rounded-xl shadow-2xl text-center max-w-md w-full mx-4 transform transition-all duration-300 ease-in-out hover:scale-105">
            <div className="relative mb-6 h-16">
              <div className={`absolute inset-0 transition-opacity duration-300 ease-in-out ${showSuccess ? 'opacity-0' : 'opacity-100'}`}>
                <Loader2 className="animate-spin h-16 w-16 mx-auto text-blue-500" />
                <div className="absolute inset-0 bg-white bg-opacity-75 rounded-full animate-pulse"></div>
              </div>
              <div className={`absolute inset-0 transition-opacity duration-300 ease-in-out ${showSuccess ? 'opacity-100' : 'opacity-0'}`}>
                <CheckCircle className="h-16 w-16 mx-auto text-green-500" />
              </div>
            </div>
            <h2 className="text-3xl font-bold mb-4 text-gray-800">{showSuccess ? 'Location Created!' : 'Creating Location'}</h2>
            <p className="text-lg text-gray-600 mb-6">
              {showSuccess ? 'Your new location has been successfully created.' : 'Please wait while we set up your new location...'}
            </p>
            <div className="w-full bg-gray-200 h-2 rounded-full overflow-hidden">
              <div
                className={`h-full rounded-full transition-all ease-out ${
                  showSuccess ? 'bg-green-500 w-full' : 'bg-blue-500 animate-progress'
                }`}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
