import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { CaretSortIcon, CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { cn } from 'lib/utils';

const CONFIRM_NEW_MESSAGE = 'You have unsaved changes to your schedule, are you sure you want to continue?';
const MAX_SCHEDULE_NAME_LEN = 30;
const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: `Name cannot be empty.`
    })
    .max(MAX_SCHEDULE_NAME_LEN, {
      message: `Must be less than ${MAX_SCHEDULE_NAME_LEN} characters.`
    })
});

export default function SelectScheduleDropdown({
  selectedSchedule,
  onSelectSchedule,
  selectableSchedules,
  onAddNewSchedule,
  allowCreateNew,
  disabled,
  unsavedChanges
}) {
  const [open, setOpen] = useState(false);
  const [showNewScheduleDialog, setShowNewScheduleDialog] = useState(false);
  const form = useForm({
    resolver: zodResolver(FormSchema)
  });

  const onNewScheduleSubmit = (data) => {
    form.reset();
    setShowNewScheduleDialog(false);
    onAddNewSchedule(data.name);
  };

  return (
    <Dialog open={showNewScheduleDialog} onOpenChange={setShowNewScheduleDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={disabled}>
          <Button variant="outline" role="combobox" aria-expanded={open} className="w-[120px] md:w-[200px] justify-between">
            <span className="truncate">
              {selectedSchedule ? selectableSchedules.find((rs) => rs.id === selectedSchedule)?.name : 'Select a rate...'}
            </span>
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search rates..." />
              <CommandEmpty>No rate found.</CommandEmpty>

              <CommandGroup key="schedules">
                {selectableSchedules.map((schedule) => (
                  <CommandItem
                    key={schedule.id}
                    onSelect={() => {
                      onSelectSchedule(schedule.id);
                      setOpen(false);
                    }}>
                    {schedule.name}
                    <CheckIcon className={cn('ml-auto h-4 w-4', selectedSchedule === schedule.id ? 'opacity-100' : 'opacity-0')} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
            {allowCreateNew && (
              <CommandList>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => {
                      if (unsavedChanges) {
                        // eslint-disable-next-line
                      const result = window.confirm(CONFIRM_NEW_MESSAGE);
                        if (!result) {
                          return;
                        }
                      }
                      setOpen(false);
                      setShowNewScheduleDialog(true);
                    }}>
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    New Rate
                  </CommandItem>
                </CommandGroup>
              </CommandList>
            )}
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onNewScheduleSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>Create rate</DialogTitle>
              <DialogDescription>Add a new weekly schedule for this location.</DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Rate name</FormLabel>
                  <FormControl>
                    <Input placeholder="Holiday rate..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowNewScheduleDialog(false)}>
                Cancel
              </Button>
              <Button type="submit">Continue</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
