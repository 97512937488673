import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useMockData } from 'context/mockDataProvider';

export function MockToggle() {
  const location = useLocation();
  const { checked, setChecked } = useMockData();

  if (location.pathname !== '/' && location.pathname !== '/client-analytics') {
    return null;
  }

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 flex items-center space-x-1">
      <Label htmlFor="mock-data" className=" cursor-pointer">
        Mock Data
      </Label>
      <Switch id="mock-data" checked={checked} onCheckedChange={handleChange} />
    </div>
  );
}
