import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';

import FlatRateTypeForm from './FlatRateTypeForm';
import IntervalRateTypeForm from './IntervalRateTypeForm';
import VariableRateSliderForm from './VariableRateSliderForm';
import { formatFlatEventTitle, formatIntervalEventTitle, formatVariableEventTitle } from './helpers';

const dayjs = require('dayjs');

export default function NewRateTypeDialog({ open, onClose, onSubmit, timeSlot, dayStartOffset }) {
  const startDayjs = timeSlot?.start ? dayjs(timeSlot?.start) : null;
  const endDayjs = timeSlot?.end ? dayjs(timeSlot?.end) : null;
  // We find the diff in seconds and manually convert and round to mins because default .diff method is to floor
  const selectDuration = endDayjs ? Math.round(endDayjs.diff(startDayjs, 'second') / 60) : 0;

  const formattedStartString = dayjs(timeSlot?.start).add(1, 'second').startOf('minute').add(dayStartOffset, 'minutes').format('dddd h:mmA');
  const formattedEndString = dayjs(timeSlot?.end).add(1, 'second').startOf('minute').add(dayStartOffset, 'minutes').format('dddd h:mmA');

  function onSubmitForm(data) {
    switch (data?.type) {
      case 'flat': {
        onSubmit({ title: formatFlatEventTitle(data.rate), start: timeSlot?.start, end: timeSlot?.end, type: 'flat', data: { rate: data.rate } });
        break;
      }
      case 'interval': {
        onSubmit({
          title: formatIntervalEventTitle(data),
          start: timeSlot?.start,
          end: timeSlot?.end,
          type: 'interval',
          data: { rate: data.rate, interval: data.interval, dailyMax: data.dailyMax }
        });
        break;
      }
      case 'variable': {
        onSubmit({
          title: formatVariableEventTitle(data.rates),
          start: timeSlot?.start,
          end: timeSlot?.end,
          type: 'variable',
          data: { rates: data.rates }
        });
        break;
      }
      default: {
        console.log('Unexpected rate type submitted from form');
        onClose();
        break;
      }
    }
  }

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Set rates for this time period</DialogTitle>
          <DialogDescription>
            {formattedStartString} - {formattedEndString}
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="flat" className="mt-3 ">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="flat">Flat Rate</TabsTrigger>
            <TabsTrigger value="interval">Interval Rate</TabsTrigger>
            <TabsTrigger value="variable">Variable</TabsTrigger>
          </TabsList>
          <TabsContent value="flat" className="focus:outline-none">
            <p className="text-sm mb-2 mt-2">Specify a single flat rate the customer pays per park.</p>
            <FlatRateTypeForm onSubmit={onSubmitForm} />
          </TabsContent>
          <TabsContent value="interval">
            <p className="text-sm mb-2 mt-2">Specify a rate the customer pays every certain number of minutes.</p>
            <IntervalRateTypeForm onSubmit={onSubmitForm} />
          </TabsContent>
          <TabsContent value="variable">
            <p className="text-sm mb-2 mt-2">Specify custom rates depending on parking duration.</p>
            <VariableRateSliderForm onSubmit={onSubmitForm} selectDuration={selectDuration} />
          </TabsContent>
        </Tabs>
        {/* <DialogFooter>
          <Button type="submit" onClick={() => onSubmit({ title: "name", start: timeSlot?.start, end: timeSlot?.end })}>Set Rate</Button>
        </DialogFooter> */}
      </DialogContent>
    </Dialog>
  );
}
