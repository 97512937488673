import { Button } from 'components/ui/button';
import { Card } from 'components/ui/card';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

export default function LocationAdded() {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const name = decodeURIComponent(queryParams.get('name'));
  const locationId = decodeURIComponent(queryParams.get('id'));

  // If the reload param is true, force page reload. This updates the permissions and the locations in the permissions object to include the newly added location
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reloadParam = queryParams.get('reload');

    if (reloadParam === 'true') {
      // Remove 'reload=true' from the URL and reload the page
      queryParams.delete('reload');
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString()
      });
      window.location.reload();
    }
  }, [location, history]);

  return (
    <div className="flex justify-center pt-10 bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
      <div className="max-w-4xl w-full px-4">
        <Card className="p-12 shadow-xl rounded-xl">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center gap-3 mb-10">
              <div className="bg-green-100 rounded-full p-2 inline-block shadow-sm">
                <CheckCircle className="h-12 w-12 text-green-500" />
              </div>
              <h1 className="text-4xl font-bold text-gray-800">Location Added Successfully!</h1>
            </div>
            <div className="space-y-4">
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 max-w-2xl mx-auto">
                <p className="text-lg text-blue-800 font-medium mb-4">
                  Important: You must set your rates in the "Configure Rates" tab before Lightning Pay can be used at this location
                </p>
                <Button
                  onClick={() => history.push('/rate-selection')}
                  className="w-72 h-12 text-lg font-semibold bg-blue-600 hover:bg-blue-700 text-white shadow-lg hover:scale-105 transition-transform">
                  Set Your Rates Now →
                </Button>
              </div>
            </div>
          </div>

          <div className="flex justify-center space-x-6 pt-2">
            <Button onClick={() => history.push(`/edit-location/${locationId}?name=${name}`)} variant="outline" className="hover:bg-gray-50">
              View Location
            </Button>
            <Button onClick={() => history.push('/add-location')} variant="outline" className="hover:bg-gray-50">
              Add Another Location
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
