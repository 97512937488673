import { useForm } from 'react-hook-form';

import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { useState } from 'react';
import { MINS_PER_DAY, displayIntervalMinsForTitle } from './helpers';

const MAX_INTERVAL_RATE_DOLLARS = 1000; // Maximum interval rate the user can enter

export default function IntervalRateTypeForm({ onSubmit, defaultValues }) {
  const form = useForm();
  const [rate, setRate] = useState(defaultValues?.rate ?? undefined);
  const [interval, setInterval] = useState(defaultValues?.interval ?? undefined);
  const [dailyMax, setDailyMax] = useState(defaultValues?.dailyMax ?? undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dailyMaxEnabled, setDailyMaxEnabled] = useState((defaultValues?.dailyMax ?? undefined) === undefined ? false : true);

  const handleSubmit = () => {
    const rateNumber = parseFloat(rate);
    const dailyMaxNumber = parseFloat(dailyMax);
    if (rateNumber === undefined || Number.isNaN(rateNumber)) {
      setErrorMessage('Please input a rate');
      return;
    }
    if (interval === undefined || Number.isNaN(interval)) {
      setErrorMessage('Please input the interval number of minutes');
      return;
    }
    if (dailyMaxEnabled && (dailyMaxNumber === undefined || Number.isNaN(dailyMaxNumber))) {
      setErrorMessage('Please input a daily max price');
      return;
    }
    if (dailyMaxEnabled && dailyMaxNumber < rateNumber) {
      setErrorMessage('Daily max cannot be less than the interval rate');
      return;
    }
    setErrorMessage(null);
    onSubmit({ rate: rateNumber, interval, dailyMax: dailyMaxEnabled ? dailyMaxNumber : undefined, type: 'interval' });
  };

  const handleRateInputChange = (value) => {
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      if (parseFloat(validated) > MAX_INTERVAL_RATE_DOLLARS) {
        setRate(MAX_INTERVAL_RATE_DOLLARS.toString());
        return;
      }
      if (parseFloat(validated) < 0) {
        setRate('0');
        return;
      }

      setRate(value);
    }
  };

  const handleIntervalInputChange = (value) => {
    // Ensure the input is a number
    if (/^\d*\.?\d*$/.test(value)) {
      const clampedValue = Math.min(MINS_PER_DAY, Math.max(0, value));
      // Update the state with the clamped value
      setInterval(clampedValue);
    } else if (interval === undefined) {
      setInterval(0);
    }
  };

  const handleDailyMaxInputChange = (value) => {
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      if (parseFloat(validated) > MAX_INTERVAL_RATE_DOLLARS) {
        setDailyMax(MAX_INTERVAL_RATE_DOLLARS.toString());
        return;
      }
      if (parseFloat(validated) < 0) {
        setDailyMax('0');
        return;
      }

      setDailyMax(value);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-4 mt-4">
        <Label className="text-xl mr-1">$</Label>
        <Input className="w-16 mr-2" value={rate} onChange={(event) => handleRateInputChange(event.target.value)} />
        <p>every</p>
        <Input className="w-16 mr-2 ml-2" value={interval} onChange={(event) => handleIntervalInputChange(event.target.value)} />
        <p>minutes</p>
      </div>
      <div>
        <div className="flex items-center space-x-2 mb-2">
          <Switch checked={dailyMaxEnabled} onCheckedChange={setDailyMaxEnabled} />
          <Label>Set Daily Max</Label>
        </div>
        {dailyMaxEnabled && (
          <div className="flex items-center mb-4 mt-4">
            <Label className="text-xl mr-1">$</Label>
            <Input className="w-16 mr-2" value={dailyMax} onChange={(event) => handleDailyMaxInputChange(event.target.value)} />
            <p>daily max</p>
          </div>
        )}
      </div>
      {errorMessage && <p className="text-[0.8rem] font-medium text-destructive">{errorMessage}</p>}
      <p className="text-sm text-muted-foreground mb-2 mt-2">
        The parker will be charged <span className="font-bold">{rate !== undefined ? `$${rate}` : '$_'}</span> every{' '}
        <span className="font-bold">{displayIntervalMinsForTitle(interval)}</span> they park within this time period
        {dailyMaxEnabled && dailyMax !== undefined && ', up to a daily max of '}
        {dailyMaxEnabled && dailyMax !== undefined && <span className="font-bold">${dailyMax}</span>}.
      </p>
      {!defaultValues && (
        <div className="flex justify-end">
          <Button onClick={handleSubmit}>Set Rate</Button>
        </div>
      )}
      <form onSubmit={form.handleSubmit(handleSubmit)} id="interval-rate-type-form"></form>
    </div>
  );
}
