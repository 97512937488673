import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';

const MockDataContext = createContext();

export const useMockData = () => useContext(MockDataContext);

export function MockDataProvider({ children }) {
  const defaultMock = localStorage.getItem('mockData') === 'true';
  const [checked, setChecked] = useState(defaultMock);

  useEffect(() => {
    localStorage.setItem('mockData', checked ? 'true' : 'false');
  }, [checked]);

  return <MockDataContext.Provider value={useMemo(() => ({ checked, setChecked }), [checked])}>{children}</MockDataContext.Provider>;
}
