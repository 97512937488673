import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';

import { Button } from 'components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { useStepper } from 'components/ui/stepper';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useEffect, useState } from 'react';
import { getParticleDevicesAPI } from '../../services/AddLocation';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectGroup, SelectLabel } from 'components/ui/select';
import { LoadingSpinner } from 'components/ui/loading-spinner';

const MAX_NUM_SERVICE_FEES = 10;

const lotDetailsFormSchema = z.object({
  gracePeriod: z.preprocess(
    (x) => (x ? x : undefined),
    z.coerce
      .number({
        required_error: 'Please input a number of minutes.',
        invalid_type_error: 'Please input a number'
      })
      .min(0, {
        message: 'Cannot have a grace period of less than 0'
      })
      .max(1440, {
        message: 'Cannot have a grace period of more than 1440 mins (24 hours)'
      })
  ),
  customAccount: z.literal(false, {
    errorMap: () => ({
      message: "Sorry we're unable to set up custom accounts through this interface right now. Please send us an email and we'll do this for you"
    })
  }),
  hasGates: z.boolean(),
  lanes: z
    .array(
      z.object({
        id: z.string({ required_error: 'Please select a gatekit' }),
        type: z.string({ required_error: 'Please select a type' })
      })
    )
    .optional(),
  isCloudpark: z.boolean(),
  fees: z
    .array(
      z.object({
        description: z.string({ required_error: 'Please enter a description' }).min(1, {
          message: 'Please enter a description'
        }),
        value: z.preprocess(
          (x) => x.replace(/[\$,%]/g, ''),
          z.coerce
            .number({
              required_error: 'Please input a number',
              invalid_type_error: 'Please input a number'
            })
            .positive({
              message: 'Must be greater than 0'
            })
        ),
        type: z.string({ required_error: 'Please select a type' })
      })
    )
    .optional(),
  taxRate: z
    .object({
      included: z.boolean(),
      rate: z
        .preprocess(
          (x) => x.replace(/[\$,%]/g, ''),
          z.coerce.number().positive({ message: 'Please enter a percentage', inclusive: false }).max(100, { message: 'Tax rate cannot exceed 100%' })
        )
        .optional()
    })
    .superRefine(({ included, rate }, refinementContext) => {
      if (!included && !rate) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a tax rate percentage',
          path: ['rate']
        });
      }
    })
});

const defaultValues = {
  hasGates: false,
  isCloudpark: false,
  customAccount: false,
  gracePeriod: ''
};

export default function LotDetailsForm({ onSubmitCallback, savingNewLocation }) {
  const { nextStep, prevStep } = useStepper();
  const [particleDevices, setParticleDevices] = useState(undefined);
  const form = useForm({
    resolver: zodResolver(lotDetailsFormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const { fields, append, remove } = useFieldArray({
    name: 'fees',
    control: form.control
  });

  const lanesFieldArray = useFieldArray({
    name: 'lanes',
    control: form.control
  });

  useEffect(() => {
    const fetchParticleDevices = async () => {
      try {
        const { success, data } = await getParticleDevicesAPI();
        if (!success || !data) {
          throw new Error('Unable to get particle devices');
        }
        setParticleDevices(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchParticleDevices();
  }, []);

  const onSubmit = async (data) => {
    const serviceFees = data.fees.map((fee) => ({
      descriptor: fee.description,
      flatAmount: fee.type === 'dollars' ? Math.round(fee.value * 100) : undefined,
      percentage: fee.type === 'percent' ? fee.value : undefined
    }));
    const lanes = data.hasGates
      ? data.lanes?.map((lane) => {
          if (lane.id === 'none') {
            return {
              ...lane,
              name: null,
              id: null
            };
          }

          const laneDevice = particleDevices.find((el) => el.id === lane.id);
          return {
            ...lane,
            name: laneDevice?.name ?? ''
          };
        })
      : undefined;

    await onSubmitCallback({
      ...data,
      gracePeriod: data.gracePeriod ? data.gracePeriod : null,
      version: data.hasGates ? 4 : 3,
      additionalTaxPercent: !data.taxRate.included ? data.taxRate.rate : undefined,
      lanes,
      serviceFees
    });
    nextStep();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="max-w-2xl text-left space-y-3 sm:space-y-8 py-6 mb-4">
          <div>
            <FormField
              control={form.control}
              name="hasGates"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between items-center">
                    <div className="mb-3 mr-2 text-left">
                      <FormLabel>Location has gates</FormLabel>
                      <FormDescription>Does your location have gates?</FormDescription>
                    </div>
                    <FormControl>
                      <Tabs
                        defaultValue="no"
                        onValueChange={(value) => {
                          field.onChange(value === 'yes');
                          form.setValue(
                            'lanes',
                            value === 'yes'
                              ? [
                                  { name: '', type: 'entry' },
                                  { name: '', type: 'exit' }
                                ]
                              : undefined
                          );
                        }}
                        value={field.value ? 'yes' : 'no'}>
                        <TabsList className="grid w-28 grid-cols-2">
                          <TabsTrigger value="no">No</TabsTrigger>
                          <TabsTrigger value="yes">Yes</TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </FormControl>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />
            {form.watch('hasGates') === true && (
              <div className="mt-3">
                <div className="text-start">
                  <p className="text-[0.8rem] text-muted-foreground mb-2">
                    Please list all lanes at your location. Each lane will get a QR code that opens its corresponding gate:
                  </p>
                </div>
                <div>
                  {lanesFieldArray.fields.map((field, index) => (
                    <div key={field.id} className="flex mb-2">
                      <FormField
                        control={form.control}
                        name={`lanes.${index}.id`}
                        render={({ field }) => (
                          <FormItem className="mr-2 flex-grow">
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a gatekit..." />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {particleDevices === undefined && <LoadingSpinner className="m-2" />}
                                {particleDevices && (
                                  <div>
                                    <SelectGroup>
                                      <SelectItem value="none" key="none">
                                        No Gatekit (Assign Later)
                                      </SelectItem>
                                    </SelectGroup>
                                    <SelectGroup>
                                      <SelectLabel>Available Gatekits:</SelectLabel>
                                      {particleDevices.map((device) => (
                                        <SelectItem value={device.id} key={device.id}>
                                          {device.name}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </div>
                                )}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name={`lanes.${index}.type`}
                        render={({ field }) => (
                          <FormItem className="w-36">
                            <Tabs defaultValue="entry" onValueChange={field.onChange} value={field.value} className="w-full">
                              <TabsList className="grid w-full grid-cols-2">
                                <TabsTrigger value="entry">Entry</TabsTrigger>
                                <TabsTrigger value="exit">Exit</TabsTrigger>
                              </TabsList>
                            </Tabs>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  ))}
                  <div className="max-sm:w-[170px]">
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      className="mt-2 mr-2"
                      onClick={() => lanesFieldArray.remove(lanesFieldArray.fields.length - 1)}
                      disabled={lanesFieldArray.fields.length <= 2}>
                      Remove lane
                    </Button>
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      className="mt-2"
                      onClick={() => lanesFieldArray.append({ name: undefined, type: 'entry' })}>
                      Add lane
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FormField
            control={form.control}
            name="isCloudpark"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center">
                  <div className="mb-3 mr-2 text-left">
                    <FormLabel>Is this a Cloudpark location?</FormLabel>
                    <FormDescription>Is your location set up to use the Cloudpark app?</FormDescription>
                  </div>
                  <FormControl>
                    <Tabs defaultValue="no" onValueChange={(value) => field.onChange(value === 'yes')} value={field.value ? 'yes' : 'no'}>
                      <TabsList className="grid w-28 grid-cols-2">
                        <TabsTrigger value="no">No</TabsTrigger>
                        <TabsTrigger value="yes">Yes</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="customAccount"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center">
                  <div className="mb-3 mr-2 text-left">
                    <FormLabel>Custom Bank Account</FormLabel>
                    <FormDescription>Does the client require funds to immediately deposit into their own bank account?</FormDescription>
                  </div>

                  <FormControl>
                    <Tabs defaultValue="no" onValueChange={(value) => field.onChange(value === 'yes')} value={field.value ? 'yes' : 'no'}>
                      <TabsList className="grid w-28 grid-cols-2">
                        <TabsTrigger value="no">No</TabsTrigger>
                        <TabsTrigger value="yes">Yes</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>
                <FormMessage />
              </FormItem>
            )}
          /> */}
          <FormField
            control={form.control}
            name="gracePeriod"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center">
                  <div className="mb-3 mr-2 text-left">
                    <FormLabel>Grace Period</FormLabel>
                    <FormDescription>How many minutes can parkers park without being charged?</FormDescription>
                  </div>
                  <FormControl>
                    <Input placeholder="30" {...field} className="w-28" />
                  </FormControl>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="pb-3">
            <FormField
              control={form.control}
              name="taxRate.included"
              render={({ field }) => (
                <FormItem>
                  <div className="mb-3 text-left">
                    <FormLabel>Tax Rate</FormLabel>
                    <FormDescription>Please describe how tax is handled at this facility</FormDescription>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(value) => field.onChange(value === 'included')}
                      value={field.value === undefined ? undefined : field.value ? 'included' : 'excluded'}
                      className="space-y-1">
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="included" />
                        </FormControl>
                        <FormLabel className="font-normal">Tax is included in my rates</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="excluded" />
                        </FormControl>
                        <FormLabel className="font-normal">Tax is charged in addition to my rates</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.watch('taxRate.included') === false && (
              <FormField
                control={form.control}
                name="taxRate.rate"
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <FormItem className="mt-3">
                      <div className="space-y-2 text-left justify-start">
                        <FormDescription>What additional tax percentage should be charged?</FormDescription>
                        <FormControl>
                          <NumericFormat
                            className="max-w-20"
                            {...rest}
                            customInput={Input}
                            allowLeadingZeros
                            thousandSeparator=","
                            suffix="%"
                            decimalScale={2}
                            allowNegative={false}
                            placeholder="5%"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  );
                }}
              />
            )}
          </div>
          <div>
            <div className="text-start">
              <Label className="mb-2">Service Fees</Label>
              <p className="text-[0.8rem] text-muted-foreground mb-2">Add any fees that the parker needs to pay at your location</p>
            </div>
            <div>
              {fields.map((field, index) => (
                <div key={field.id} className="flex mb-2">
                  {/* Fee Description Input */}
                  <FormField
                    control={form.control}
                    name={`fees.${index}.description`}
                    render={({ field }) => (
                      <FormItem className="mr-2 flex-grow">
                        <FormControl>
                          <Input {...field} placeholder="Fee Description" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Fee Value Input */}
                  <FormField
                    control={form.control}
                    name={`fees.${index}.value`}
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <FormItem className="w-24 mr-2">
                          <FormControl>
                            {form.watch(`fees.${index}.type`) === 'percent' ? (
                              <NumericFormat
                                {...rest}
                                customInput={Input}
                                allowLeadingZeros
                                thousandSeparator=","
                                suffix="%"
                                decimalScale={2}
                                allowNegative={false}
                                placeholder="5%"
                              />
                            ) : (
                              <NumericFormat
                                {...rest}
                                customInput={Input}
                                allowLeadingZeros
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                placeholder="$5.00"
                              />
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />

                  <FormField
                    control={form.control}
                    name={`fees.${index}.type`}
                    render={({ field }) => (
                      <FormItem className="w-24">
                        <Tabs defaultValue="dollars" onValueChange={field.onChange} value={field.value} className="w-full">
                          <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="dollars">$</TabsTrigger>
                            <TabsTrigger value="percent">%</TabsTrigger>
                          </TabsList>
                        </Tabs>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
              <div className="max-sm:w-[170px]">
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="mt-2 mr-2"
                  onClick={() => remove(fields.length - 1)}
                  disabled={!fields.length}>
                  Remove fee
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  className="mt-2"
                  onClick={() => append({ description: 'Technology Charge', value: '', type: 'dollars' })}
                  disabled={fields.length >= MAX_NUM_SERVICE_FEES}>
                  Add fee
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between max-sm:mx-2">
          <Button onClick={prevStep} disabled={savingNewLocation} variant="secondary">
            Previous
          </Button>
          <Button type="submit" disabled={savingNewLocation}>
            Confirm & Add Location
          </Button>
        </div>
      </form>
    </Form>
  );
}
