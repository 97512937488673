import * as SliderPrimitive from '@radix-ui/react-slider';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { MinusCircle, PlusCircle } from 'lucide-react';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area-max-height';
import { useState } from 'react';
import { calendarVariableEventColors } from './colors';
import { useForm } from 'react-hook-form';
import { MINS_PER_DAY, displayMinsAsHoursAndMins } from './helpers';

const MAX_SLOTS = 40; // The maximum number of time slots the user is allowed to add for a variable rate
const MAX_SLOT_RATE_DOLLARS = 1000;
const SLIDER_STEP_MINS = 5; // Sets the granularity of the slider

const tooltipDisplayMins = (minutes) => {
  if (Number.isNaN(minutes) || minutes < 0) {
    return 'NaN';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}h ${remainingMinutes}m`;
};

export default function VariableRateSliderForm({ onSubmit, selectDuration, defaultValues = null }) {
  // User can only set rates for a 24 hour period. The rate engine automatically loops over to a new 24 hour period if a parker parks for more than 24 hours
  selectDuration = Math.min(selectDuration, MINS_PER_DAY);
  const [slots, setSlots] = useState(
    defaultValues
      ? Object.keys(defaultValues).map((i) => ({
          name: `slot${i}`,
          start: defaultValues[i].start,
          end: defaultValues[i].end,
          price: defaultValues[i].rate
        }))
      : [{ name: 'slot0', start: 0, end: selectDuration }]
  );
  // Hacky use of form here, but I think it's the easiest way to keep things consistent. Basically the button to trigger submit
  // is in the parent component (when defaultValues != null) and the button is linked to a form. This form is only to trigger submit function when clicked
  const form = useForm();

  const plusButtonClicked = (e) => {
    // Prevents the form from submitting on click
    e.preventDefault();
    setSlots((old) => [...old, { name: `slot${old.length}`, start: old[old.length - 1].end, end: selectDuration }]);
  };

  const minusButtonClicked = (e) => {
    // Prevents the form from submitting on click
    e.preventDefault();
    setSlots((old) => old.slice(0, -1));
  };

  const setNewRange = (range, name) => {
    setSlots((prevList) => prevList.map((item) => (item.name === name ? { ...item, end: Math.max(range[1], item.start + SLIDER_STEP_MINS) } : item)));
  };

  const handleSubmit = () => {
    // sets errors to display
    setSlots((prevList) =>
      prevList.map((s) =>
        parseFloat(s.price) === undefined || Number.isNaN(parseFloat(s.price)) ? { ...s, error: 'Please input a number' } : { ...s, error: null }
      )
    );

    // Find out synchronously whether there were any input errors
    const inputErrors = slots.find((s) => parseFloat(s.price) === undefined || Number.isNaN(parseFloat(s.price)));
    if (inputErrors) return;

    const rates = slots.map((s) => ({ start: s.start, end: s.end, rate: parseFloat(s.price) }));
    onSubmit({ rates, type: 'variable' });
  };

  const handleInputChange = (value, slotName) => {
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      if (parseFloat(validated) > MAX_SLOT_RATE_DOLLARS) {
        setSlots((prevList) => prevList.map((item) => (item.name === slotName ? { ...item, price: MAX_SLOT_RATE_DOLLARS.toString() } : item)));
        return;
      }
      if (parseFloat(validated) < 0) {
        setSlots((prevList) => prevList.map((item) => (item.name === slotName ? { ...item, price: '0' } : item)));
        return;
      }
      setSlots((prevList) => prevList.map((item) => (item.name === slotName ? { ...item, price: value } : item)));
    }
  };

  return (
    <div>
      <ScrollArea className="rounded-md" maxHeight="max-h-96">
        {slots.map((slot, idx) => (
          <div className="rounded-lg border p-4 mb-3 pt-5 " key={slot.name}>
            <SliderPrimitive.Root
              className="relative flex w-full touch-none select-none items-center mr-8"
              value={[slot.start, slot.end]}
              onValueChange={(range) => setNewRange(range, slot.name)}
              max={selectDuration}
              step={SLIDER_STEP_MINS}
              disabled={idx < slots.length - 1}>
              <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20">
                <SliderPrimitive.Range
                  className="absolute h-full "
                  style={{ backgroundColor: calendarVariableEventColors[idx % calendarVariableEventColors.length] }}
                />
              </SliderPrimitive.Track>
              <SliderPrimitive.Thumb />

              {idx < slots.length - 1 ? (
                <SliderPrimitive.Thumb />
              ) : (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-5 active:scale-125 cursor-pointer " />
                  </TooltipTrigger>
                  <TooltipContent side="top" sideOffset={5}>
                    {tooltipDisplayMins(slot.end)}
                  </TooltipContent>
                </Tooltip>
              )}
            </SliderPrimitive.Root>
            <div className="flex justify-between mt-4 items-center" key={slot.name}>
              {slot.start === 0 ? (
                <p className="text-xs text-muted-foreground">
                  First <span className="font-bold">{displayMinsAsHoursAndMins(slot.end)}</span> parker pays:
                </p>
              ) : (
                <p className="text-xs text-muted-foreground">
                  From <span className="font-bold">{displayMinsAsHoursAndMins(slot.start)}</span> to{' '}
                  <span className="font-bold">{displayMinsAsHoursAndMins(slot.end)}</span> parker pays an additional:
                </p>
              )}
              <div className="flex items-center">
                <Label className="text-xl mr-1">$</Label>
                <Input className="w-16" value={slot.price} onChange={(event) => handleInputChange(event.target.value, slot.name)} />
              </div>
            </div>

            {slot.error && <p className="text-[0.8rem] font-medium text-destructive">{slot.error}</p>}
          </div>
        ))}
      </ScrollArea>

      <div className="flex items-center justify-center">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" disabled={slots.length <= 1} size="icon" onClick={minusButtonClicked}>
              <MinusCircle className="h-6 w-6" />
              <span className="sr-only">Delete One</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Delete One</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              disabled={slots.length >= MAX_SLOTS || slots[slots.length - 1].end === selectDuration}
              size="icon"
              onClick={plusButtonClicked}>
              <PlusCircle className="h-6 w-6" />
              <span className="sr-only">Add One</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Add One</TooltipContent>
        </Tooltip>
      </div>

      <p className="text-sm text-muted-foreground mb-2 mt-2">
        For example, a maximum <span className="font-bold">{displayMinsAsHoursAndMins(selectDuration)}</span> park will cost{' '}
        <span className="font-bold">{`$${slots.reduce((acc, curr) => (curr.price ? acc + parseFloat(curr.price) : acc), 0)}`}</span>
      </p>

      {!defaultValues && (
        <div className="flex justify-end">
          <Button disabled={slots[slots.length - 1].end !== selectDuration} onClick={handleSubmit}>
            Set Rate
          </Button>
        </div>
      )}

      <form onSubmit={form.handleSubmit(handleSubmit)} id="variable-rate-type-form"></form>
    </div>
  );
}
