import { Cross2Icon, UploadIcon } from '@radix-ui/react-icons';
import { DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { useToast } from 'components/ui/use-toast';
import Dropzone from 'react-dropzone';

import { Button } from 'components/ui/button';
import { Progress } from 'components/ui/progress';
import { ScrollArea } from 'components/ui/scroll-area';
import { useControllableState } from 'hooks/use-controllable-state';
import { cn, formatBytes } from 'lib/utils';
import { useCallback, useEffect } from 'react';

// Reference https://github.com/sadmann7/file-uploader

export function FileUploader(props) {
  const {
    value: valueProp,
    onValueChange,
    onUpload,
    progresses,
    accept = { 'image/*': [] },
    maxSize = 1024 * 1024 * 2,
    maxFiles = 1,
    multiple = false,
    disabled = false,
    className,
    ...dropzoneProps
  } = props;
  const { toast } = useToast();

  const [files, setFiles] = useControllableState({
    prop: valueProp,
    onChange: onValueChange
  });

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (!multiple && maxFiles === 1 && acceptedFiles.length > 1) {
        toast({
          variant: 'destructive',
          title: 'Cannot upload more than 1 file at a time'
        });
        return;
      }

      if ((files?.length ?? 0) + acceptedFiles.length > maxFiles) {
        toast({
          variant: 'destructive',
          title: `Cannot upload more than ${maxFiles} files`
        });
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      const updatedFiles = files ? [...files, ...newFiles] : newFiles;

      setFiles(updatedFiles);

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(({ file }) => {
          toast({
            variant: 'destructive',
            title: `File ${file.name} was rejected`
          });
        });
      }

      if (onUpload && updatedFiles.length > 0 && updatedFiles.length <= maxFiles) {
        setFiles([]);
        onUpload(updatedFiles);
      }
    },

    [files, maxFiles, multiple, onUpload, setFiles, toast]
  );

  function onRemove(index) {
    if (!files) return;
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    onValueChange?.(newFiles);
  }

  // Revoke preview url when component unmounts
  useEffect(
    () => () => {
      if (!files) return;
      files.forEach((file) => {
        if (isFileWithPreview(file)) {
          URL.revokeObjectURL(file.preview);
        }
      });
    },
    []
  );

  const isDisabled = disabled || (files?.length ?? 0) >= maxFiles;

  return (
    <div className="px-6">
      <DialogHeader className="mb-4">
        <DialogTitle>Upload photo</DialogTitle>
        <DialogDescription>Drag and drop your photo here or click to browse.</DialogDescription>
      </DialogHeader>
      <div className="relative flex flex-col gap-6 overflow-hidden">
        <Dropzone onDrop={onDrop} accept={accept} maxSize={maxSize} maxFiles={maxFiles} multiple={maxFiles > 1 || multiple} disabled={isDisabled}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={cn(
                'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
                'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                isDragActive && 'border-muted-foreground/50',
                isDisabled && 'pointer-events-none opacity-60',
                className
              )}
              {...dropzoneProps}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                  <div className="rounded-full border border-dashed p-3">
                    <UploadIcon className="size-7 text-muted-foreground" aria-hidden="true" />
                  </div>
                  <p className="font-medium text-muted-foreground">Drop the files here</p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                  <div className="rounded-full border border-dashed p-3">
                    <UploadIcon className="size-7 text-muted-foreground" aria-hidden="true" />
                  </div>
                  <div className="space-y-px">
                    <p className="font-medium text-muted-foreground">Drag {`'n'`} drop files here, or click to select files</p>
                    <p className="text-sm text-muted-foreground/70">
                      You can upload
                      {maxFiles > 1
                        ? ` ${maxFiles === Infinity ? 'multiple' : maxFiles}
                      files (up to ${formatBytes(maxSize)} each)`
                        : ` a file with ${formatBytes(maxSize)}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {files?.length ? (
          <ScrollArea className="h-fit w-full px-3">
            <div className="max-h-48 space-y-4">
              {files?.map((file, index) => (
                <FileCard key={index} file={file} onRemove={() => onRemove(index)} progress={progresses?.[file.name]} />
              ))}
            </div>
          </ScrollArea>
        ) : null}
      </div>
    </div>
  );
}

function FileCard({ file, progress, onRemove }) {
  return (
    <div className="relative flex items-center space-x-4">
      <div className="flex flex-1 space-x-4">
        {isFileWithPreview(file) ? (
          <img src={file.preview} alt={file.name} width={48} height={48} loading="lazy" className="aspect-square shrink-0 rounded-md object-cover" />
        ) : null}
        <div className="flex w-full flex-col gap-2">
          <div className="space-y-px">
            <p className="line-clamp-1 text-sm font-medium text-foreground/80">{file.name}</p>
            <p className="text-xs text-muted-foreground">{formatBytes(file.size)}</p>
          </div>
          {progress ? <Progress value={progress} /> : null}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button type="button" variant="outline" size="icon" className="size-7" onClick={onRemove}>
          <Cross2Icon className="size-4 " aria-hidden="true" />
          <span className="sr-only">Remove file</span>
        </Button>
      </div>
    </div>
  );
}

function isFileWithPreview(file) {
  return 'preview' in file && typeof file.preview === 'string';
}
