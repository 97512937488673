import React, { createContext, useEffect, useState, useMemo } from 'react';
import validatePermissionsFromContext from '../util/validatePermissions';

const STORAGE_KEY = 'lpa';

const persistState = (storageKey, permissions) => {
  if (!permissions) {
    localStorage.removeItem(storageKey);
    return;
  }
  localStorage.setItem(storageKey, JSON.stringify(permissions));
};

const getInitialState = (storageKey) => {
  const savedState = localStorage.getItem(storageKey);
  try {
    if (!savedState) {
      return {};
    }
    return JSON.parse(savedState ?? '{}');
  } catch (err) {
    console.log('Error loading permissions', err);
    return {};
  }
};

function MyProvider(props) {
  const initialState = getInitialState(STORAGE_KEY);
  const [permissions, setPermissions] = useState(initialState);

  useEffect(() => {
    const session = localStorage.getItem(STORAGE_KEY);
    let initialState = {};
    if (session) {
      initialState = JSON.parse(session ?? '{}');
      setPermissions(initialState);
    }
  }, []);

  useEffect(() => {
    persistState(STORAGE_KEY, permissions);
  }, [permissions]);

  const validatePermissions = (data) => validatePermissionsFromContext({ ...data, permissions: permissions?.featureLevelLocations });

  return (
    <AppContext.Provider value={useMemo(() => ({ permissions, setPermissions, validatePermissions }), [permissions, setPermissions])}>
      {props.children}
    </AppContext.Provider>
  );
}

export default MyProvider;
export const AppContext = createContext();
