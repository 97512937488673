export const allStatesList = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District of Columbia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' }
];

export const usTimezonesList = [
  {
    name: 'America/Adak',
    offset: 540
  },
  {
    name: 'America/Anchorage',
    offset: 480
  },
  {
    name: 'America/Boise',
    offset: 360
  },
  {
    name: 'America/Chicago',
    offset: 300
  },
  {
    name: 'America/Denver',
    offset: 360
  },
  {
    name: 'America/Detroit',
    offset: 240
  },
  {
    name: 'America/Indiana/Indianapolis',
    offset: 240
  },
  {
    name: 'America/Indiana/Knox',
    offset: 300
  },
  {
    name: 'America/Indiana/Marengo',
    offset: 240
  },
  {
    name: 'America/Indiana/Petersburg',
    offset: 240
  },
  {
    name: 'America/Indiana/Tell_City',
    offset: 300
  },
  {
    name: 'America/Indiana/Vevay',
    offset: 240
  },
  {
    name: 'America/Indiana/Vincennes',
    offset: 240
  },
  {
    name: 'America/Indiana/Winamac',
    offset: 240
  },
  {
    name: 'America/Juneau',
    offset: 480
  },
  {
    name: 'America/Kentucky/Louisville',
    offset: 240
  },
  {
    name: 'America/Kentucky/Monticello',
    offset: 240
  },
  {
    name: 'America/Los_Angeles',
    offset: 420
  },
  {
    name: 'America/Menominee',
    offset: 300
  },
  {
    name: 'America/Metlakatla',
    offset: 480
  },
  {
    name: 'America/New_York',
    offset: 240
  },
  {
    name: 'America/Nome',
    offset: 480
  },
  {
    name: 'America/North_Dakota/Beulah',
    offset: 300
  },
  {
    name: 'America/North_Dakota/Center',
    offset: 300
  },
  {
    name: 'America/North_Dakota/New_Salem',
    offset: 300
  },
  {
    name: 'America/Phoenix',
    offset: 420
  },
  {
    name: 'America/Sitka',
    offset: 480
  },
  {
    name: 'America/Yakutat',
    offset: 480
  },
  {
    name: 'Pacific/Honolulu',
    offset: 600
  }
];

export const basicUsTimezonesMapping = {
  'America/New_York': 'Eastern Time',
  'America/Chicago': 'Central Time',
  'America/Denver': 'Mountain Time',
  'America/Los_Angeles': 'Pacific Time'
};
