import React, { forwardRef } from 'react';
import { TRANSIENT_RESERVATIONS_PHOTO_ASPECT_RATIO } from '../sharedvariables';

const PHOTO_THUMBNAIL_HEIGHT_PX = 100;

const Item = forwardRef(({ id, withOpacity, isDragging, style, photo, setImageExpanded, ...props }, ref) => {
  const inlineStyles = {
    opacity: withOpacity ? '0.5' : '1',
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'scale(1.10)' : 'scale(1)',
    boxShadow: isDragging ? 'rgb(63 63 68 / 10%) 0px 2px 6px 2px, rgb(34 33 81 / 20%) 0px 2px 6px 2px' : 'none',

    ...style
  };

  if (!photo) {
    return <></>;
  }

  return (
    <div ref={ref} {...props} className="p-2">
      <figure
        key={photo.name}
        className="transition-transform transform hover:scale-110 overflow-hidden rounded-md"
        style={inlineStyles}
        onClick={() => setImageExpanded(photo)}>
        <img
          src={photo.url}
          style={{
            width: `${PHOTO_THUMBNAIL_HEIGHT_PX * TRANSIENT_RESERVATIONS_PHOTO_ASPECT_RATIO}px`,
            height: `${PHOTO_THUMBNAIL_HEIGHT_PX}px`
          }}
          alt={photo.name}
        />
      </figure>
    </div>
  );
});

export default Item;
