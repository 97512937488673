import { auth } from '../services/firebase';

export default async function authErrorHandler(error) {
  if (
    error.response.data?.error === 'TokenExpired' ||
    error.response.data?.error === 'NoTokenProvided' ||
    error.response.data?.error === 'InvalidToken'
  ) {
    localStorage.removeItem('lpa');
    await auth.signOut();
    window.location.href = '/login';
  }
}
