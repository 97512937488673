import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { Calendar } from 'components/ui/calendar';

export default function BlackoutDates({
  control,
  name,
  blackoutDatesData,
  selectedDates,
  setSelectedDates,
  setSelectedAddBlackoutDates,
  setSelectedRemoveBlackoutDates,
  isSaving
}) {
  const handleDateChange = (date) => {
    const updatedBlackoutDatesData = blackoutDatesData?.map((d) => ({
      id: d.id,
      blackout_date: dayjs(d.blackout_date ? d.blackout_date.split('T')[0] : dayjs()).format('YYYY-MM-DD')
    }));
    const updatedDates = date?.map((d) => dayjs(d || dayjs()).format('YYYY-MM-DD'));

    const idsToRemove = [];
    const datesToAdd = [];
    updatedDates.forEach((selectedDate) => {
      const blackoutDateEntry = updatedBlackoutDatesData.find((data) => data.blackout_date === selectedDate);
      if (blackoutDateEntry) {
        idsToRemove.push(blackoutDateEntry.id);
      } else {
        datesToAdd.push(selectedDate);
      }
    });

    const remainingIds = updatedBlackoutDatesData.filter((data) => !idsToRemove.includes(data.id)).map((data) => data.id);

    setSelectedRemoveBlackoutDates(remainingIds);
    setSelectedAddBlackoutDates(datesToAdd);
  };

  const handleSelectDate = (selectedDate) => {
    setSelectedDates(selectedDate);
    handleDateChange(selectedDate);
  };

  return (
    <Controller
      control={control}
      name={`${name}`}
      render={() => (
        <Calendar
          mode="multiple"
          className="p-0"
          selected={selectedDates}
          onSelect={handleSelectDate}
          initialFocus
          disabled={(date) => {
            const formattedDate = dayjs(date).startOf('day').toDate();
            return formattedDate < dayjs().startOf('day').toDate() || isSaving;
          }}
        />
      )}
    />
  );
}
