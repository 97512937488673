import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { useToast } from 'components/ui/use-toast';
import { useState } from 'react';
import { addLocationPhotoAPI, deleteLocationPhotoAPI, getPhotosForLocationAPI, reorderLocationPhotoAPI } from '../../services/ManageLocations';
import { getFileURL } from '../../services/getFileURL';
import { uploadFile } from '../../services/uploadFile';
import { FileUploader } from './FileUploader';
import ImageCropper from './ImageCropper';
import SortablePhotos from './SortablePhotos/SortablePhotos';
import { TR_PHOTO_USER_PREVIEW_HEIGHT, TR_PHOTO_USER_PREVIEW_WIDTH } from './sharedvariables';

export default function ManagePhotos({ locationId, initialPhotos, disabled = false }) {
  const [files, setFiles] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [imageExpanded, setImageExpanded] = useState(null);
  const [photos, setPhotos] = useState(initialPhotos);
  const [cropPhoto, setCropPhoto] = useState(null);
  const { toast } = useToast();

  const getAllPhotos = async () => {
    try {
      const { success, data } = await getPhotosForLocationAPI({ locationId });
      if (!success || !data) throw new Error('Unable to get photos for location');

      const orderedLocationPhotos = data.slice().sort((a, b) => a.orderIndex - b.orderIndex);
      setPhotos(orderedLocationPhotos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const uploadPhotosToCloud = async (files) => {
    toast({
      title: `Uploading photo...`,
      description: 'Please be patient. This may take a while'
    });
    setIsUploading(true);
    try {
      for (const file of files) {
        await uploadFile(file.file, `photos/${locationId}/${file.name}/${file.resolution}`);
      }
      // We've uploaded all resolutions of the photo to the cloud, now lets put the default resolution url in our database
      const fileName = files[0]?.name;
      const storagePath = `photos/${locationId}/${fileName}`;
      const defaultResURL = await getFileURL(`${storagePath}/${TR_PHOTO_USER_PREVIEW_WIDTH}x${TR_PHOTO_USER_PREVIEW_HEIGHT}`);
      const { success, data } = await addLocationPhotoAPI({
        payload: {
          locationId,
          photoURL: defaultResURL,
          storageFolder: storagePath,
          photoName: fileName
        }
      });
      if (!success || !data) {
        throw new Error('Unable to add new photo');
      }

      toast({
        title: `Photo uploaded`
      });
    } catch (error) {
      console.error(error);
      toast({
        title: `Failed to upload photo`
      });
    }
    setIsUploading(false);

    await getAllPhotos();
    setCropPhoto(null);
    setUploadDialogOpen(false);
  };

  const deletePhotoFromCloud = async (photoName) => {
    if (!photoName) {
      return;
    }
    toast({
      title: `Deleting photo...`,
      description: 'This may take a while'
    });
    setIsDeleting(true);
    try {
      const { success, data } = await deleteLocationPhotoAPI({
        payload: { locationId, photoName }
      });
      if (!success || !data) throw new Error('Unable to delete photo');

      toast({
        title: `Photo deleted!`
      });
    } catch (error) {
      console.error(error);
      toast({
        title: `Failed to delete photo`
      });
    }
    setIsDeleting(false);

    await getAllPhotos();
    setImageExpanded(null);
  };

  const onPhotoOrderChanged = async (photoOrder) => {
    try {
      const { success } = await reorderLocationPhotoAPI({
        payload: { locationId, photoOrder }
      });
      if (!success) throw new Error(`Unable to reorder photos for location ${locationId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Label className="pb-10">Photos</Label>
      <p className="text-[0.8rem] text-muted-foreground mb-1">
        These will be seen by customers when booking a transient reservation. Click to edit, click and hold to drag and reorder.
      </p>

      {photos?.length > 0 && <SortablePhotos photos={photos} setImageExpanded={setImageExpanded} onImageOrderChanged={onPhotoOrderChanged} />}
      <Dialog
        open={uploadDialogOpen}
        onOpenChange={(isopen) => {
          if (!isopen) {
            setCropPhoto(null);
          }
          setUploadDialogOpen(isopen);
        }}>
        <DialogTrigger asChild>
          <Button variant="outline" className="mt-2" disabled={disabled}>
            Upload photos {files.length > 0 && `(${files.length})`}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-xl px-0">
          {cropPhoto ? (
            <ImageCropper photoFile={cropPhoto} uploadPhotosToCloud={uploadPhotosToCloud} isUploading={isUploading} />
          ) : (
            <FileUploader maxFiles={1} maxSize={8 * 1024 * 1024} onValueChange={setFiles} onUpload={(uploadFiles) => setCropPhoto(uploadFiles[0])} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={!!imageExpanded} onOpenChange={(isOpen) => !isOpen && setImageExpanded(null)}>
        <DialogContent className="pt-12 px-4">
          <div
            style={{ width: `${TR_PHOTO_USER_PREVIEW_WIDTH}px`, height: `${TR_PHOTO_USER_PREVIEW_HEIGHT}px` }}
            className="overflow-hidden rounded-md border">
            <img src={imageExpanded?.url} className="w-full h-full object-cover" />
          </div>
          <DialogFooter>
            <Button variant="destructive" onClick={() => deletePhotoFromCloud(imageExpanded?.name)} disabled={isDeleting || disabled}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
