// export const calendarColorPalette = ['#FDF2FF', '#F8DDFE', '#ECC4F9', '#D6A6EC', '#B482D1', '#8354A6', '#5A377C', '#382251', '#191026'];

// export const calendarColorPalette = ['#F2F9FF', '#C3E7FF', '#94D8FD', '#64CCF7', '#34C2ED', '#149EBB', '#067A8A', '#025358', '#012626'];
// export const calendarColorPalette = ['#AFFCAF', '#95F7BA', '#7BF2C6', '#61EED1', '#46E9DC', '#2CE4E8', '#12DFF3'];
// export const calendarColorPalette = ['#211F2F', '#2F2D3E', '#3D3A4E', '#4B485D', '#59566C', '#67637B', '#75718B', '#837E9A', '#918CA9'];
// export const calendarColorPalette = ['#EDC4B3', '#E6B8A2', '#DEAB90', '#D69F7E', '#CD9777', '#C38E70', '#B07D62', '#9D6B53', '#8A5A44', '#774936'];

// export const calendarFlatEventColor = calendarColorPalette[6];
// export const calendarIntervalEventColor = calendarColorPalette[6];
export const calendarErrorEventColor = '#EAFE79';

// export const calendarVariableEventColors = calendarColorPalette.slice(4);

export const materialColorPallet = {
  description: 'TYPE: CUSTOM\nMaterial Theme Builder export 2024-04-18 01:54:43',
  seed: '#673AB7',
  coreColors: {
    primary: '#673AB7'
  },
  extendedColors: [],
  schemes: {
    light: {
      primary: '#68548E',
      surfaceTint: '#68548E',
      onPrimary: '#FFFFFF',
      primaryContainer: '#EBDDFF',
      onPrimaryContainer: '#230F46',
      secondary: '#635B70',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#E9DEF8',
      onSecondaryContainer: '#1F182B',
      tertiary: '#7E525D',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#FFD9E1',
      onTertiaryContainer: '#31101B',
      error: '#BA1A1A',
      onError: '#FFFFFF',
      errorContainer: '#FFDAD6',
      onErrorContainer: '#410002',
      background: '#FEF7FF',
      onBackground: '#1D1B20',
      surface: '#FEF7FF',
      onSurface: '#1D1B20',
      surfaceVariant: '#E7E0EB',
      onSurfaceVariant: '#49454E',
      outline: '#7A757F',
      outlineVariant: '#CBC4CF',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#322F35',
      inverseOnSurface: '#F5EFF7',
      inversePrimary: '#D3BCFD',
      primaryFixed: '#EBDDFF',
      onPrimaryFixed: '#230F46',
      primaryFixedDim: '#D3BCFD',
      onPrimaryFixedVariant: '#4F3D74',
      secondaryFixed: '#E9DEF8',
      onSecondaryFixed: '#1F182B',
      secondaryFixedDim: '#CDC2DB',
      onSecondaryFixedVariant: '#4B4358',
      tertiaryFixed: '#FFD9E1',
      onTertiaryFixed: '#31101B',
      tertiaryFixedDim: '#F0B7C5',
      onTertiaryFixedVariant: '#643B46',
      surfaceDim: '#DED8E0',
      surfaceBright: '#FEF7FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F8F1FA',
      surfaceContainer: '#F2ECF4',
      surfaceContainerHigh: '#EDE6EE',
      surfaceContainerHighest: '#E7E0E8'
    },
    'light-medium-contrast': {
      primary: '#4B3970',
      surfaceTint: '#68548E',
      onPrimary: '#FFFFFF',
      primaryContainer: '#7F6AA5',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#473F54',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#797187',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#5F3742',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#976774',
      onTertiaryContainer: '#FFFFFF',
      error: '#8C0009',
      onError: '#FFFFFF',
      errorContainer: '#DA342E',
      onErrorContainer: '#FFFFFF',
      background: '#FEF7FF',
      onBackground: '#1D1B20',
      surface: '#FEF7FF',
      onSurface: '#1D1B20',
      surfaceVariant: '#E7E0EB',
      onSurfaceVariant: '#45414A',
      outline: '#625D67',
      outlineVariant: '#7E7983',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#322F35',
      inverseOnSurface: '#F5EFF7',
      inversePrimary: '#D3BCFD',
      primaryFixed: '#7F6AA5',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#65528B',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#797187',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#60586E',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#976774',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#7C4F5B',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#DED8E0',
      surfaceBright: '#FEF7FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F8F1FA',
      surfaceContainer: '#F2ECF4',
      surfaceContainerHigh: '#EDE6EE',
      surfaceContainerHighest: '#E7E0E8'
    },
    'light-high-contrast': {
      primary: '#2A164D',
      surfaceTint: '#68548E',
      onPrimary: '#FFFFFF',
      primaryContainer: '#4B3970',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#251F32',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#473F54',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#391722',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#5F3742',
      onTertiaryContainer: '#FFFFFF',
      error: '#4E0002',
      onError: '#FFFFFF',
      errorContainer: '#8C0009',
      onErrorContainer: '#FFFFFF',
      background: '#FEF7FF',
      onBackground: '#1D1B20',
      surface: '#FEF7FF',
      onSurface: '#000000',
      surfaceVariant: '#E7E0EB',
      onSurfaceVariant: '#26222B',
      outline: '#45414A',
      outlineVariant: '#45414A',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#322F35',
      inverseOnSurface: '#FFFFFF',
      inversePrimary: '#F3E8FF',
      primaryFixed: '#4B3970',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#352258',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#473F54',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#30293D',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#5F3742',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#46212C',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#DED8E0',
      surfaceBright: '#FEF7FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F8F1FA',
      surfaceContainer: '#F2ECF4',
      surfaceContainerHigh: '#EDE6EE',
      surfaceContainerHighest: '#E7E0E8'
    },
    dark: {
      primary: '#D3BCFD',
      surfaceTint: '#D3BCFD',
      onPrimary: '#38265C',
      primaryContainer: '#4F3D74',
      onPrimaryContainer: '#EBDDFF',
      secondary: '#CDC2DB',
      onSecondary: '#342D40',
      secondaryContainer: '#4B4358',
      onSecondaryContainer: '#E9DEF8',
      tertiary: '#F0B7C5',
      onTertiary: '#4A2530',
      tertiaryContainer: '#643B46',
      onTertiaryContainer: '#FFD9E1',
      error: '#FFB4AB',
      onError: '#690005',
      errorContainer: '#93000A',
      onErrorContainer: '#FFDAD6',
      background: '#151218',
      onBackground: '#E7E0E8',
      surface: '#151218',
      onSurface: '#E7E0E8',
      surfaceVariant: '#49454E',
      onSurfaceVariant: '#CBC4CF',
      outline: '#948F99',
      outlineVariant: '#49454E',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E7E0E8',
      inverseOnSurface: '#322F35',
      inversePrimary: '#68548E',
      primaryFixed: '#EBDDFF',
      onPrimaryFixed: '#230F46',
      primaryFixedDim: '#D3BCFD',
      onPrimaryFixedVariant: '#4F3D74',
      secondaryFixed: '#E9DEF8',
      onSecondaryFixed: '#1F182B',
      secondaryFixedDim: '#CDC2DB',
      onSecondaryFixedVariant: '#4B4358',
      tertiaryFixed: '#FFD9E1',
      onTertiaryFixed: '#31101B',
      tertiaryFixedDim: '#F0B7C5',
      onTertiaryFixedVariant: '#643B46',
      surfaceDim: '#151218',
      surfaceBright: '#3B383E',
      surfaceContainerLowest: '#0F0D13',
      surfaceContainerLow: '#1D1B20',
      surfaceContainer: '#211F24',
      surfaceContainerHigh: '#2C292F',
      surfaceContainerHighest: '#36343A'
    },
    'dark-medium-contrast': {
      primary: '#D7C0FF',
      surfaceTint: '#D3BCFD',
      onPrimary: '#1D0840',
      primaryContainer: '#9B86C4',
      onPrimaryContainer: '#000000',
      secondary: '#D1C6DF',
      onSecondary: '#191325',
      secondaryContainer: '#968DA4',
      onSecondaryContainer: '#000000',
      tertiary: '#F5BCC9',
      onTertiary: '#2B0B16',
      tertiaryContainer: '#B68390',
      onTertiaryContainer: '#000000',
      error: '#FFBAB1',
      onError: '#370001',
      errorContainer: '#FF5449',
      onErrorContainer: '#000000',
      background: '#151218',
      onBackground: '#E7E0E8',
      surface: '#151218',
      onSurface: '#FFF9FF',
      surfaceVariant: '#49454E',
      onSurfaceVariant: '#CFC8D3',
      outline: '#A7A1AB',
      outlineVariant: '#86818B',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E7E0E8',
      inverseOnSurface: '#2C292F',
      inversePrimary: '#513E75',
      primaryFixed: '#EBDDFF',
      onPrimaryFixed: '#18023B',
      primaryFixedDim: '#D3BCFD',
      onPrimaryFixedVariant: '#3E2C62',
      secondaryFixed: '#E9DEF8',
      onSecondaryFixed: '#140E20',
      secondaryFixedDim: '#CDC2DB',
      onSecondaryFixedVariant: '#3A3346',
      tertiaryFixed: '#FFD9E1',
      onTertiaryFixed: '#250611',
      tertiaryFixedDim: '#F0B7C5',
      onTertiaryFixedVariant: '#512A36',
      surfaceDim: '#151218',
      surfaceBright: '#3B383E',
      surfaceContainerLowest: '#0F0D13',
      surfaceContainerLow: '#1D1B20',
      surfaceContainer: '#211F24',
      surfaceContainerHigh: '#2C292F',
      surfaceContainerHighest: '#36343A'
    },
    'dark-high-contrast': {
      primary: '#FFF9FF',
      surfaceTint: '#D3BCFD',
      onPrimary: '#000000',
      primaryContainer: '#D7C0FF',
      onPrimaryContainer: '#000000',
      secondary: '#FFF9FF',
      onSecondary: '#000000',
      secondaryContainer: '#D1C6DF',
      onSecondaryContainer: '#000000',
      tertiary: '#FFF9F9',
      onTertiary: '#000000',
      tertiaryContainer: '#F5BCC9',
      onTertiaryContainer: '#000000',
      error: '#FFF9F9',
      onError: '#000000',
      errorContainer: '#FFBAB1',
      onErrorContainer: '#000000',
      background: '#151218',
      onBackground: '#E7E0E8',
      surface: '#151218',
      onSurface: '#FFFFFF',
      surfaceVariant: '#49454E',
      onSurfaceVariant: '#FFF9FF',
      outline: '#CFC8D3',
      outlineVariant: '#CFC8D3',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E7E0E8',
      inverseOnSurface: '#000000',
      inversePrimary: '#321F55',
      primaryFixed: '#EEE2FF',
      onPrimaryFixed: '#000000',
      primaryFixedDim: '#D7C0FF',
      onPrimaryFixedVariant: '#1D0840',
      secondaryFixed: '#EEE2FC',
      onSecondaryFixed: '#000000',
      secondaryFixedDim: '#D1C6DF',
      onSecondaryFixedVariant: '#191325',
      tertiaryFixed: '#FFDFE5',
      onTertiaryFixed: '#000000',
      tertiaryFixedDim: '#F5BCC9',
      onTertiaryFixedVariant: '#2B0B16',
      surfaceDim: '#151218',
      surfaceBright: '#3B383E',
      surfaceContainerLowest: '#0F0D13',
      surfaceContainerLow: '#1D1B20',
      surfaceContainer: '#211F24',
      surfaceContainerHigh: '#2C292F',
      surfaceContainerHighest: '#36343A'
    }
  },
  palettes: {
    primary: {
      0: '#000000',
      5: '#18003F',
      10: '#250059',
      15: '#320073',
      20: '#3F008D',
      25: '#4B169B',
      30: '#5727A6',
      35: '#6336B3',
      40: '#6F43C0',
      50: '#895EDB',
      60: '#A478F7',
      70: '#BC99FF',
      80: '#D3BBFF',
      90: '#EBDDFF',
      95: '#F7EDFF',
      98: '#FEF7FF',
      99: '#FFFBFF',
      100: '#FFFFFF'
    },
    secondary: {
      0: '#000000',
      5: '#150C25',
      10: '#201731',
      15: '#2A213B',
      20: '#352C47',
      25: '#403752',
      30: '#4C425E',
      35: '#584E6A',
      40: '#645977',
      50: '#7D7291',
      60: '#978BAB',
      70: '#B2A6C7',
      80: '#CEC1E3',
      90: '#EBDDFF',
      95: '#F7EDFF',
      98: '#FEF7FF',
      99: '#FFFBFF',
      100: '#FFFFFF'
    },
    tertiary: {
      0: '#000000',
      5: '#290210',
      10: '#370B1B',
      15: '#441525',
      20: '#512030',
      25: '#5E2B3B',
      30: '#6C3646',
      35: '#7A4152',
      40: '#874D5D',
      50: '#A46576',
      60: '#C17E8F',
      70: '#DE97AA',
      80: '#FDB2C5',
      90: '#FFD9E1',
      95: '#FFECEF',
      98: '#FFF8F8',
      99: '#FFFBFF',
      100: '#FFFFFF'
    },
    neutral: {
      0: '#000000',
      5: '#121014',
      10: '#1D1B1E',
      15: '#272529',
      20: '#323033',
      25: '#3D3A3E',
      30: '#48464A',
      35: '#545155',
      40: '#605D61',
      50: '#79767A',
      60: '#938F94',
      70: '#AEAAAE',
      80: '#CAC5CA',
      90: '#E6E1E6',
      95: '#F5EFF4',
      98: '#FEF8FD',
      99: '#FFFBFF',
      100: '#FFFFFF'
    },
    'neutral-variant': {
      0: '#000000',
      5: '#121017',
      10: '#1D1A22',
      15: '#28242D',
      20: '#322F37',
      25: '#3E3A43',
      30: '#49454E',
      35: '#55515A',
      40: '#615D66',
      50: '#7A757F',
      60: '#948F99',
      70: '#AFA9B4',
      80: '#CBC4CF',
      90: '#E7E0EB',
      95: '#F6EEFA',
      98: '#FEF7FF',
      99: '#FFFBFF',
      100: '#FFFFFF'
    }
  }
};
const primaryPalette = materialColorPallet.palettes.primary;
export const calendarVariableEventColors = [primaryPalette[20], primaryPalette[40], primaryPalette[60]];
